import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, AfterViewInit, Output, QueryList, ViewChild, ViewChildren, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { EditableColumn, Table } from 'primeng/table';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { FocusableEvent, FocusableEventType } from 'src/app/core/directives/focusable-table.directive';
import { INote } from 'src/app/core/interfaces/inotes';
import { Constants } from 'src/app/core/models/constants';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { Role } from 'src/app/core/models/enumerations/role';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { MessageEvent, MessageResponse, MessageType } from '../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { SessionService } from 'src/app/core/services/session.service';
import { TableUtils } from 'src/app/core/utils/table-utils';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})

export class NotesComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() notes: Array<INote> = [];
    @Input() entityId: number;
    @Input() showSell: boolean = true;
    @Input() baseIndex?: number;
    @Input() createInstance: (obj: any) => INote;
    @Output() noteChanges: EventEmitter<Array<INote>> = new EventEmitter();
    @ViewChildren(EditableColumn) private editableColumns: QueryList<EditableColumn>
    @ViewChild('comments') inputComment: ElementRef;
    @ViewChild('filterNote') filterNote: ElementRef;
    @ViewChild('notesDt') notesDt: Table;

    notesFocusManager = new Subject<FocusableEvent>();

    filteredNotes: Array<INote>;
    applyFilters: boolean = true;
    maxCommentLength: number = 2000;
    addRowButtonDisabled: boolean = false;
    disableCellEditor: boolean = false;
    cols: any[] = [];
    disableComponent: Subscription;
    editing: boolean = false;
    cellEditingPreviousValue: string;
    hasIndustryManagerRole: boolean;
    disabledAddButton: boolean;

    constructor(
        private messageHandler: MessageHandlerService,
        private headerService: HeaderService,
        private authService: AuthService,
        private session: SessionService,
        private sharedService: SharedService,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        if (this.showSell) {
            this.cols = [
                { field: 'createDate', header: 'Date', editable: false, style: 'flex-basis: 15%; text-align: left' },
                { field: 'createUserName', header: 'User', editable: false, style: 'flex-basis: 15%; text-align: left' },
                { field: 'comments', header: 'Comments', editable: true, style: 'flex-basis: 55%; text-align: left' },
                { field: 'notesSaleFlagBool', header: 'Sell', editable: true, style: 'flex-basis: 10%; text-align: center' },
                { field: 'delete', delete: true, style: 'flex-basis: 5%; text-align: left' }
            ];
        } else {
            this.cols = [
                { field: 'createDate', header: 'Date', editable: false, style: 'flex-basis: 20%; text-align: left' },
                { field: 'createUserName', header: 'User', editable: false, style: 'flex-basis: 20%; text-align: left' },
                { field: 'comments', header: 'Comments', editable: true, style: 'flex-basis: 55%; text-align: left' },
                { field: 'delete', delete: true, style: 'flex-basis: 5%; text-align: left' }
            ];
        }
        this.addRowButtonDisabled = false;
        this.disableComponent = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.addRowButtonDisabled = disable;
            this.disableCellEditor = disable;
        });
        this.checkIndustryManagerRole();
        this.isDisabledAddButton();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.notes) {
            this.isDisabledAddButton();
        }
    }

    ngAfterViewInit(): void {
        this.notesFocusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.editableColumns));
    }

    ngOnDestroy(): void {
        this.disableComponent.unsubscribe();
    }

    checkIndustryManagerRole() {
        this.authService.isUserInRole(Role.INDUSTRY_MANAGER).subscribe(b => this.hasIndustryManagerRole = b);
    }

    notesFiltered(): Array<INote> {
        if (this.notes) {
            this.filteredNotes = MVTOperations.filterByDeleted(this.notes);
            const nsfExist = this.notes.filter(p => p.notesSaleFlagBool)?.length > 0;
            if (nsfExist) {
                this.filteredNotes = MVTOperations.sortBySaleFlag(this.filteredNotes);
            } else {
                this.filteredNotes = MVTOperations.sortByMvOrder(this.filteredNotes, 'desc');
            }
        }
        return this.filteredNotes;
    }

    isDisabledAddButton() {
        this.disabledAddButton = this.addRowButtonDisabled || this.hasIncompleteRow();
    }

    hasIncompleteRow(): boolean {
        const notDeletedNotes = MVTOperations.filterByDeleted(this.notes) || [];
        return notDeletedNotes.filter(el => el.getOperation() === OperationType.TEMPORAL &&
            (el.comments === null || String(el.comments).trim() === '')).length > 0;
    }

    deleteNote(row: INote) {
        if (!this.disableCellEditor) {
            let msg = Constants.CONFIRM_SELL_NOTE_CHANGE_BLNAK;
            if (row.notesSaleFlagBool) {
                const selectOpt = (resp: MessageResponse): void => {
                    if (resp.event == MessageEvent.YES) {
                        MVTOperations.removeItem(this.notes, 'mvOrder', String(row.mvOrder));
                        this.headerService.changeForm = true;
                    }
                    this.changeDetector.detectChanges();
                }
                this.messageHandler.show(msg, MessageType.CONFIRM, selectOpt);
            } else {
                MVTOperations.removeItem(this.notes, 'mvOrder', String(row.mvOrder));
                this.headerService.changeForm = true;
            }
            MVTOperations.arrangeMvOrder(this.notes);
        }
        this.validateAddButton();
        this.changeDetector.detectChanges();
    }

    addRow() {
        this.notesDt.filterDelay = 10;
        this.filterNote.nativeElement.value = '';
        this.notesDt.filterGlobal('', 'contains');
        const emptyTemporalObject: boolean = this.notes?.filter(p => p.getOperation() === OperationType.TEMPORAL)?.length > 0;
        if (!emptyTemporalObject) {
            this.headerService.changeForm = true;
            const itemAux: INote = this.createInstance({
                entityId: this.entityId,
                createDate: String(Date.now()),
                createUserName: this.session.getPersonFullName(),
                createUserId: this.session.getPersonId(),
                comments: '',
                notesSaleFlag: '0',
                notesSaleFlagBool: false,
                mvOrder: MVTOperations.getLastMvOrder(this.notes) + 1,
            });
            itemAux.setOperation(OperationType.TEMPORAL);
            this.notes.push(itemAux);
            this.changeDetector.detectChanges();
            this.noteChanges.emit(this.notes);
            this.onCommentEdited(itemAux);
            this.setNewRowAutofocus(itemAux);
        }
    }

    setNewRowAutofocus(row: INote): void {
        //Autofocus
        setTimeout(() => {
            if (this.editableColumns.length !== undefined) {
                try {
                    this.editableColumns.filter(x => x.el.nativeElement.classList.contains('comments') && x.data.operation === OperationType.TEMPORAL)[0].openCell();
                } catch (ex) { }
            }
        },
            15);
    }

    onCommentEdited(row: INote) {
        if (row.comments && row.comments.trim().length > 0) {
            row.comments = row.comments.trim();
            if (row.comments.length > this.maxCommentLength) {
                row.comments = row.comments.substring(0, this.maxCommentLength);
            }
            this.markAsChanged(row, 'comments');
            MVTOperations.sortBySaleFlag(this.notes);
            this.changeDetector.detectChanges();
        } else {
            this.validateOnCleanComment(row);
        }

    }

    validateAddButton() {
        this.filteredNotes = MVTOperations.filterByDeleted(this.notes);
        const note = this.filteredNotes.find((row) => !row.comments || row.comments.trim().length == 0);
        this.disabledAddButton = note ? true : false;
    }


    validateOnCleanComment(row: INote) {
        let msg = Constants.CONFIRM_SELL_NOTE_CHANGE_BLNAK;
        if (row.notesSaleFlagBool) {
            const selectOpt = (resp: MessageResponse): void => {
                if (resp.event == MessageEvent.YES) {
                    row.notesSaleFlagBool = false;
                } else if (resp.event == MessageEvent.NO) {
                    row.comments = this.cellEditingPreviousValue;
                    this.validateAddButton();
                }
            }
            this.messageHandler.show(msg, MessageType.CONFIRM, selectOpt);
        }
    }

    onEditInit(event: any) {
        if (event.field === 'comments') {
            this.cellEditingPreviousValue = event.data[event.field];
        }
    }

    onEditComplete(event: any): void {
        if (event.field === 'comments') {
            this.verifyCellWasEdited(event);
        }
    }

    verifyCellWasEdited(event: any): void {
        const cellValue = event.data[event.field] ? event.data[event.field].toString().trim() : null;
        const cellPreviousValue = this.cellEditingPreviousValue ? this.cellEditingPreviousValue.toString() : null;
        if (cellValue != cellPreviousValue) {
            this.headerService.changeForm = true;
        }
    }

    isMyNote(row: INote): boolean {
        return row.createUserId == this.session.getPersonId();
    }

    onFocusOut(col: any, row: INote): void {
        if (col.field === 'comments') {
            row.comments = row.comments ? row.comments.trim() : null;
            this.onCommentEdited(row);
            this.validateAddButton();
        }
    }

    onFocus(col: any): void {
        this.disabledAddButton = col.field === 'comments';
    }

    onKeyDown(event: any) {
        if (event.which == 13) {
            event.stopPropagation();
        }
        
        TableUtils.restrictArrowKeyNavigation(event);
    }

    onChangeNotesSaleFlag(event: any, row: INote) {
    }

    onModelChangeNotesSaleFlag(event: any, row: INote) {
        this.headerService.changeForm = true;
        this.applyFilters = false;
        if (row.notesSaleFlagBool) {
            setTimeout(() => {
                this.checkSell(event, row);
            }, 200);
        } else {
            row.notesSaleFlagBool = false;
            this.markAsChanged(row, 'notesSaleFlag');
            this.applyFilters = true;
        }
        this.changeDetector.detectChanges();
    }

    checkSell(checkSell: boolean, row: INote) {
        let msg = Constants.CONFIRM_SELL_NOTE_NEW;
        const nroSellChequed = this.notes.filter(p => p.notesSaleFlagBool)?.length;
        if (nroSellChequed > 1) {
            msg = Constants.CONFIRM_SELL_NOTE_CHANGE;
        }

        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.YES) {
                for (let i = 0; i < this.notes.length; i++) {
                    const note = this.notes[i];
                    if (note.getOperation() !== OperationType.DELETE && note.notesSaleFlagBool) {
                        this.markAsChanged(note, 'notesSaleFlag');
                        note.notesSaleFlagBool = false;
                    }
                }

                row.notesSaleFlagBool = true;
            } else if (resp.event == MessageEvent.NO) {
                if (checkSell) {
                    row.notesSaleFlagBool = false;
                }
            }

            this.changeDetector.detectChanges();
        }
        this.messageHandler.show(msg, MessageType.CONFIRM, selectOpt);
        this.markAsChanged(row, 'notesSaleFlag');
        this.applyFilters = true;
    }

    private markAsChanged(row: INote, field: string) {
        row.changedFields = { ...row.changedFields };
        row.changedFields[field] = 0;
        row.setOperation(row.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
    }

    validateDisableSellCheckbox(comment: string) {
        return this.disableCellEditor || comment === null || comment.length === 0;
    }

    filterOnInput(event: Event): void {
        this.notesDt.filterGlobal(event.target['value'], 'contains')
    }

}
