<div class="container-modal">
    <p-messages></p-messages>
    <div *ngIf="isMainAccess" class="">
        <div class="d-flex justify-content-start pl-4 mt-1">
            <button [tabindex]="baseIndex + 0" 
                (keydown)="focusCloseButton($event, true)"
                (click)="clear()" 
                class="btn-custom">{{'clear'| translate}}</button>
        </div>
        <div class="mt-3">
            <form>
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">
                        <!-- Unit Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #unitname
                                    appFocusElement
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="unitName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.unitName"
                                    pInputText 
                                    maxlength="80" 
                                    autofocus 
                                    [tabindex]="baseIndex + 1"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Owner Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.ownerName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #ownerName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="ownerName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.ownerName"
                                    pInputText 
                                    maxlength="80"
                                    [tabindex]="baseIndex + 2"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Unit ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #unitId
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="unitId"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.unitId"
                                    pInputText
                                    appOnlyNumbers
                                    maxlength="80"
                                    [tabindex]="baseIndex + 3"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Plant Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.plantName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #plantName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="plantName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.plantName"
                                    pInputText 
                                    maxlength="80"
                                    [tabindex]="baseIndex + 4"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Asset ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.assetId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #assetId
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="assetId"
                                    appOnlyNumbers
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.assetId"
                                    pInputText
                                    maxlength="80"
                                    [tabindex]="baseIndex + 5"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Asset Name -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.assetName'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    #assetName
                                    class="form__input-field-style w-full"
                                    type="text"
                                    name="assetName"
                                    (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.assetName"
                                    pInputText 
                                    maxlength="80"
                                    [tabindex]="baseIndex + 6"/>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-6 -mt-3 sm:-mt-3 lg:mt-2 xl:mt-2">
                        <!-- Unit Type -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.unitType'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="unitTypes"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="unitTypeId,unitTypeDesc"
                                    resetFilterOnHide="false"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="unitType"
                                    [(ngModel)]="searchParams.unitType" 
                                    optionLabel="unitTypeDesc" 
                                    optionValue="unitTypeId"
                                    [tabindex]="baseIndex + 7">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- SIC Code -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.sicCode'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="unitsSicCodes"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="sicCode,sicCodeDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="false"
                                    (keyup.enter)="search($event)"
                                    name="sicCode"
                                    [(ngModel)]="searchParams.sicCode"
                                    optionLabel="sicCodeDescription"
                                    optionValue="sicCode"
                                    [tabindex]="baseIndex + 8">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Industry Code -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.industryCode'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="industry"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="industryCode,industryDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="industry"
                                    [(ngModel)]="searchParams.industryCode"
                                    optionLabel="industryDescription"
                                    optionValue="industryCode"
                                    [tabindex]="baseIndex + 9">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Plant ID -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.plantId'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    class="form__input-field-style w-full"
                                    type="text"
                                    (keyup.enter)="search()"
                                    name="plantId"
                                    [(ngModel)]="searchParams.plantId"
                                    pInputText
                                    appOnlyNumbers
                                    maxlength="40"
                                    [tabindex]="baseIndex + 10"/>
                            </div>
                        </div>
                        <!-- Record Status -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label
                                class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchOfflineEvent.recordStatus'|
                                translate}}:</label>
                            <div class="col-6 flex px-1">
                                <p-dropdown
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="recordStatuses"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    showClear="true"
                                    filterBy="RecordStatusID,RecordStatusDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="recordStatus"
                                    [(ngModel)]="searchParams.recordStatus"
                                    optionLabel="RecordStatusDescription"
                                    optionValue="RecordStatusID"
                                    [tabindex]="baseIndex + 11">
                                </p-dropdown>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr *ngIf="unitsIds == null" class="hr-modal">
    <div class="container-info-modal">
        <div class="grid main-content">
            <div class="col-6 text-xs" *ngIf="isMainAccess">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'|
                    translate}}</span>
            </div>
            <div class="col-6 text-xs" *ngIf="isMainAccess">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}:</strong> {{'common.searchKeyboardUsers'|
                    translate}}</span>
            </div>
            <div class="col-12" *ngIf="!isMainAccess">
                <span class="text-xs">{{'offlineEvent.offlineEventSubtitle'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="container-table-modal">

        <app-base-mvt-table-selector
            #mvtTableSelector
            [editable]="true"
            [disabledButton]="true"
            
            [columnsInfo]="getColumnsInfo()"
            [entitiesArray]="offlineEvents"
            [tKeyComponentTitle]="''"
            [componentDescription]="'release.subtitleReleaseGrids' | translate"
            [emptyMessage]="''"
            [showAddRowButton]="false"
            [showPaginator]="true"
            [sortingMode]="'multiple'"
            [selectionMode]="isMainAccess ? 'none' : 'multiple no check all'"
            [onRowDoubleClick]="onRowDblclick"
            
            [additionalInfoFields]="getAdditionalInfo()"

            [implementedComponent]="this"
            [baseIndex]="baseIndex + 100">
    </app-base-mvt-table-selector>

        
    </div>
    
    <div *ngIf="!isMainAccess">
        <div class="grid mt-2">
            <div class="col-6 text-left">
                <label class="text-sm">{{'common.records'| translate}}: {{offlineEvents.length}}</label>
            </div>
            <div class="col-6 text-right">
                <button [disabled]="!this.hasSelectedEntities()" class="btn-row" style="width: auto;"
                    [tabindex]="baseIndex + 120"
                    (keydown)="focusCloseButton($event)"
                    (click)="addOfflineEvents()">
                    {{'offlineEvent.addItems'| translate}}
                  </button>
            </div>
        </div>
    </div>

    <div *ngIf="isMainAccess" class="grid pt-3">
        <div class="col-12 lg:col-11 xl:col-11 flex justify-content-end">
            <label class="custom-label mt-2 lg:mr-2 xl:-mr-1">{{'project.searchOfflineEvent.infoSearchOfflineEvent'| translate}}
                <strong> {{'project.searchOfflineEvent.notDuplicate'| translate}}</strong>
            </label>
        </div>
        <div class="col-12 lg:col-1 xl:col-1 flex justify-content-end">
            <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                class="col-4 btn-custom h-2rem" label="Create New Record"
                [tabindex]="baseIndex + 120"
                (keydown.Tab)="focusCloseButton($event)"
                (click)="newRecord()">{{'project.searchOfflineEvent.newRecordButton'| translate}}
            </button>
        </div>
    </div>
