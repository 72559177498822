import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Bean } from './core/models/bean';
import { DeactivatableEntity } from './core/models/deactivatable-entity';
import { MVTOperations } from './core/mvt-operations';
import { RecordLockingFlow } from './core/record-locking/record-locking-flow';
import { StringUtils } from './core/utils/string-utils';
import { FocusableTableMVTDirective } from './core/directives/focusable-table-mvt.directive';
import { Preference } from './core/models/common';
import { PreferencesSectionService } from './preferences-section/preferences-section.service';
import { Constants, PreferencePrefix, PreferenceSufix } from './core/models/constants';
import { EntityName } from './core/models/enumerations/entity-name';

@Directive()
export abstract class BaseComponent implements DeactivatableEntity{
    private changedFields = [];
    private forcedChangedFields: Set<string>;
    private ignoreFields: Array<string>;
    private remapControls: Map<string, string>;
    private fieldsSubject$ = new BehaviorSubject<Object>({});
    fieldsChange$ = this.fieldsSubject$.asObservable();
    private formValueChange:Subscription;
    @Input() preferences: Array<Preference> = [];
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;


    constructor(public recordLockingFlow: RecordLockingFlow,
                public preferencesService: PreferencesSectionService) {}

    watchChangeForm(formGroup: FormGroup, ignoreFields: Array<string> = [], remapControls: Map<string, string> = null) {
        this.ignoreFields = ignoreFields;
        this.remapControls = remapControls;
        this.resetForcedChangedFields();

        this.formValueChange = formGroup.valueChanges.subscribe(value => {
            this.changedFields = [];
            this.forcedChangedFields.forEach(p=>value[p]='0');
            Object.keys(value).map(key => {
                this.checkDirtyControl(formGroup.get(key), key);
            })
            this.fieldsSubject$.next(Object.fromEntries( this.changedFields ));
        });
    }

    forceFieldsChanges(fields: string[]) {
        fields.forEach(field => this.forceFieldChange(field));
    }

    forceFieldChange(field: string) {
        this.changeHandler(field, true);
    }

    resetForcedChangedFields() {
        this.forcedChangedFields = new Set<string>();
    }

    resetChangedFields(): void{
        this.changedFields = [];
        this.resetForcedChangedFields();
    }

    private changeHandler(field: string, publish: boolean): void {
        let changedField = field;
        if(!this.ignoreFields.includes(field)) {
            if(this.remapControls !== null && this.remapControls.has(field)) {
                changedField = this.remapControls.get(field);
            }
            this.changedFields.push([`${changedField}`, '0']);
        }

        if(publish) {
            this.forcedChangedFields.add(changedField);
            this.fieldsSubject$.next(Object.fromEntries( this.changedFields ));
        }
    }

    private checkDirtyControl(control: AbstractControl, controlName: string): void {
        const isForcedField = this.forcedChangedFields.has(controlName);
        if((control?.dirty && !control?.pristine) || isForcedField) {
            this.changeHandler(controlName, false);
        }
    }

    public isChangedField(fieldName): boolean {
        return Object.fromEntries(this.changedFields)[fieldName] ? true : false;
    }

    public checkNoSymbols(event:any): boolean {
        return StringUtils.checkNoSymbols(event.key);
    }

    // TODO: Ideally a beforeunload handler should by dynamically registered/unregistered for when it is applicable.  In our case that would likely be when records are loaded/unloaded.
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: BeforeUnloadEvent) {
        if(this.preventClose()){
            event.preventDefault();
        }
    }

    @HostListener('window:pagehide', ['$event'])
    pagehideHandler(event: PageTransitionEvent) {
        if(!event.persisted) {
           this.doOnUnloadBase();
           if(this.preventClose()){
               this.doOnUnload();
           }
        }
    }

    preventClose(): boolean{
        return false;
    }

    hasUnsavedChanges(): boolean{
        return false;
    }

    getEntityName(): string{
        return "";
    }

    doOnUnloadBase(): void{
        this.recordLockingFlow.endLockRefreshTime();
    }

    doOnUnload(): void{}

    setStateForm(formGroup: FormGroup) {
        this.formValueChange?.unsubscribe();
        formGroup.updateValueAndValidity();
        formGroup.clearValidators();
        formGroup.markAsPristine();
    }

    clearWholeNumberCommas(value: string): string{
        return value !== null ? String(value).replace(/,/g, '') : value;
    }

    formattedToNumber(value: string): number {
        return StringUtils.isEmpty(value) ? null : Number(this.clearWholeNumberCommas(value));
    }

    getMaskWholeLimit(wholeLimit: number): string {
        const letterToRepeat:string = '9';
        return letterToRepeat.repeat(wholeLimit);
    }

    deleteTemporalMvts() : void{
        MVTOperations.deleteTemporalMvts(this.getMvtList());
    }

    getMvtList(): Array<Array<Bean>>{
        return [];
    }

    isNullOrEmpty(value: any): boolean{
        return value === null || value === undefined || String(value) === '' || String(value).trim() === '';
    }

    compareTo(value1: any, value2: any): boolean{
        return this.stringNeverNull(value1) === this.stringNeverNull(value2) ;
    }

    stringNeverNull(value: any): string{
       return  !this.isNullOrEmpty(value) ? String(value) : '';
    }

    numberToString(value: number): string{
        return value !== null ? String(value) :  null
    }

    loadPaginatorPreferences<T extends BaseComponent>(prefixes: Array<string>, implementedComponent: T, callDoAfterLoadPreferences: boolean = true) {
        if(this.getEntityName() !== EntityName.PIPELINE_LTSA && 
                this.getEntityName() !== EntityName.UNIT_LTSA &&
                this.getEntityName() !== EntityName.PLANT_LTSA &&
                this.getEntityName() !== EntityName.RELEASE) {
            prefixes.push(PreferencePrefix.RelatedInfo);
        }
        prefixes.push(StringUtils.removeWhiteSpace(this.getEntityName()));
        this.preferencesService.loadPaginatorPreferences(prefixes, implementedComponent, callDoAfterLoadPreferences);
    }

    getPreferenceResourceName(prefix: string, sufix: string): string {
        return this.preferencesService.getPreferenceResourceName(prefix, sufix);
    }

    doAfterLoadPreferences(){};

    focusLastElementOnTab(event: Event): void {
        if ((<KeyboardEvent>event).shiftKey && (<KeyboardEvent>event).key === 'Tab') {
            event.preventDefault();
            event.stopImmediatePropagation();

            const inputElement = (event.target as HTMLElement);
            const originalTabIndex = inputElement.getAttribute('tabindex');

            const focusableElements = BaseComponent.getFocusableElements();
            if (focusableElements.length > 0) {
                const elementToFocus = focusableElements[focusableElements.length - 1];
                inputElement.setAttribute('tabindex', elementToFocus.getAttribute('tabindex') + 1);

                elementToFocus.focus();
                
                inputElement.setAttribute('tabindex', originalTabIndex);
            }
        }
    }

    focusFirstElementOnTab(event: Event): void {
        if ((<KeyboardEvent>event).key === 'Tab') {
            event.preventDefault();
            event.stopImmediatePropagation();

            const focusableElements = BaseComponent.getFocusableElements();
            if (focusableElements.length > 0) {
                focusableElements[0].focus();
            }
        }
    }

    public static getFocusableElements(minTabIndex: number = null): HTMLElement[] {
        return Array.from(document.querySelectorAll('[tabindex]:not(p-scrolltop)'))
            .filter((element): element is HTMLElement => {
                const tabIndex = element.getAttribute('tabindex');
                if(minTabIndex != null && tabIndex != null 
                    && Number(tabIndex) < minTabIndex){
                    return false;
                }

                return element instanceof HTMLElement 
                    && tabIndex !== null 
                    && tabIndex !== '-1' 
                    && tabIndex !== '0' 
                    && FocusableTableMVTDirective.isFocusable(element);
            })
            .sort((a, b) => Number(a.getAttribute('tabindex')) - Number(b.getAttribute('tabindex')));
    }
}
