import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
//model
import { IContact } from 'src/app/core/interfaces/icontacts';
import { Contact } from 'src/app/core/models';
//components
import { BaseModalComponent } from '../base-modals.component';
//Services
import { TranslateService } from '@ngx-translate/core';
import { ContactDetailsModalComponent, ContactModalData } from 'src/app/contact-details-section/contact-details-modal/contact-details-modal.component';
import { CompaniesContactInfoDisplay } from 'src/app/core/models/company/display/companies-contact-info-display';
import { CompanyStatusValue, Constants, ContactStatusValue, EntityPreferenceSufix, RecordStatusValue } from 'src/app/core/models/constants';
import { ContactOperation } from 'src/app/core/models/contact';
import { ContactsDisplay } from 'src/app/core/models/contact/display/contacts-display';
import { ContactStatus } from 'src/app/core/models/enumerations/contact-status';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { ServerDateService } from 'src/app/core/services/serverdate.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { ContactService } from '../../../core/services/contact.service';
import { MessageEvent, MessageResponse, MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact-modal.component.html',
    styleUrl: './contact-modal.component.scss',
})

export class ContactModalComponent extends BaseModalComponent implements OnInit {
    @ViewChild('inputLastName', { static: false }) inputLastName: ElementRef;
    @ViewChild('inputContactId', { static: false }) inputContactId: ElementRef;
    @ViewChild('inputEmail', { static: false }) inputEmail: ElementRef;
    @ViewChild('inputCompanyName', { static: false }) inputCompanyName: ElementRef;
    @ViewChild('inputFirstName', { static: false }) inputFirstName: ElementRef;
    @ViewChild('buttonSearch', { static: false }) buttonSearch: ElementRef;
    @ViewChild('buttonClear', { static: false }) buttonClear: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    @Input() companyContacts: Array<Contact> = [];
    @Input() createInstance: (obj: any) => IContact;
    disableButton: boolean = true;
    disableBtnNewContact: boolean = true;
    entityName: string;
    allowNewContacts: boolean = false;
    checkOptedOutContacts: boolean = false;
    additionalInfo: Contact;
    cols: any = [] = [];
    contactModalForm: FormGroup;
    recordStatusDesc: string;
    companyStatusDesc: string;
    readonly trashStatus = 'Trash';
    readonly incompleteStatus = 'Incomplete';
    readonly suspendedUnresolved = 'Suspended / Unresolved';
    readonly closed = 'Closed';
    contactStatuses = { DEAD: 'Dead', TRASH: 'Trash', MOVED: 'Moved', RETIRED: 'Retired' };

    constructor(
        public activeModal: DynamicDialogRef,
        public dialogService: DialogService,
        public preferencesService: PreferencesSectionService,
        private serverDate: ServerDateService,
        public config: DynamicDialogConfig,
        private messageHandlerService: MessageHandlerService,
        public contactService: ContactService,
        public fb: FormBuilder,
        private translate: TranslateService
    ) { super(activeModal, config, preferencesService); }

    ngOnInit(): void {
        this.initPaginatorPreferences();
        this.buildForm();
        this.cols = [
            { field: 'FirstName', header: this.translate.instant('contact.common.firstName') },
            { field: 'LastName', header: this.translate.instant('contact.common.lastName') },
            { field: 'ContactID', header: this.translate.instant('contact.common.contactId') },
            { field: 'CompanyName', header: this.translate.instant('common.company') },
            { field: 'CompanyID', header: this.translate.instant('common.companyId') },
            { field: 'ContactTitleDesc', header: this.translate.instant('contact.contactModal.functionalTitle') },
            { field: 'ContactStatus', header: this.translate.instant('common.status') },
            { field: 'QcDate', header: this.translate.instant('common.qcDate') },
            { field: 'EntityRelation', header: this.translate.instant('common.entity') }
        ];

        if (this.config.data.companyName !== null) {
            this.contactModalForm.get('InputsFilters.CompanyName').setValue(this.config.data.companyName);
        } else {
            this.contactModalForm.get('InputsFilters.CompanyName').setValue('');
        }

        this.entityName = this.config?.data?.entityName ?? '';
        this.allowNewContacts = this.config?.data?.allowNewContacts ?? true;
        this.checkOptedOutContacts = this.config?.data?.checkOptedOutContacts ?? false;
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onRowDblclick(contact: Contact) {
        if (this.isDisabledRow(contact)) return;

        const contactStatus = contact.ContactStatus;
        if (contactStatus !== ContactStatusValue.Dead && contactStatus !== ContactStatusValue.Trash) {
            if (contactStatus === ContactStatusValue.Retired || contactStatus === ContactStatusValue.Moved) {
                const confirmFunction = (resp: MessageResponse): void => {
                    if (resp.event === MessageEvent.YES) {
                        this.closeAfterDblclick(contact);
                    }
                }
                const msg = Constants.replace(Constants.CONFIRM_ADD_CONTACT, [contactStatus]);
                this.messageHandlerService.show(msg, MessageType.CONFIRM, confirmFunction);
            } else {
                this.closeAfterDblclick(contact);
            }
        }
    }

    closeAfterDblclick(contact: Contact) {
        const contactStatus = contact.ContactStatus;
        if (contactStatus !== ContactStatus.DEAD && contactStatus !== ContactStatus.TRASH) {
            if (this.checkOptedOutContacts || contact.ContactOptedOut) {
                this.optedOutContactCheckConfirm(contact);
            } else {
                this.getContactEntityInfoAndCloseModal(contact);
            }
        }
    }

    optedOutContactCheckConfirm(contact: Contact) {
        if (contact.ContactOptedOut) {
            const doSelectContact = (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.YES) {
                    this.getContactEntityInfoAndCloseModal(contact);
                }
            }

            if (contact.Status != ContactStatusValue.Moved
                && contact.Status != ContactStatusValue.Retired
                && contact.Status != ContactStatusValue.Dead
                && contact.Status != ContactStatusValue.Trash) {
                this.messageHandlerService.show(this.translate.instant('contact.project.confirmSelectOptOut'), MessageType.CONFIRM, doSelectContact);
            }
        } else {
            this.getContactEntityInfoAndCloseModal(contact);
        }
    }

    getContactEntityInfoAndCloseModal(contact: Contact) {
        const mvId = String(contact.MvId);
        const entityId = String(contact.EntityId);
        const contactId = String(contact.ContactID);
        const entityType = contact.EntityRelation;

        this.contactService.getContactEntityInfo(entityType, entityId, contactId, mvId).subscribe((cEntityInfo: any) => {
            if (cEntityInfo) {
                contact.merge(cEntityInfo);
            }
            this.activeModal.close(contact);
        }, () => {
            this.activeModal.close(contact);
        });
    }

    onRowclick(contact: any) {
        this.additionalInfo = contact;
    }

    viewCompleteContactInfo() {
        if (this.additionalInfo) {
            if (this.entityName == EntityName.PROJECT) {
                this.openContactDetailsInReadOnly(this.additionalInfo);
            } else {
                this.additionalInfo.Operation = ContactOperation.ADD_AND_VIEW;
                this.activeModal.close(this.additionalInfo);
            }
        }
    }

    openContactDetailsInReadOnly(contact: Contact) {
        const mvId = String(contact.MvId);
        const entityId = String(contact.EntityId);
        const contactId = String(contact.ContactID);
        const entityType = contact.EntityRelation;
        const contactDisplay: ContactsDisplay = ContactsDisplay.CreateInstance({});

        contactDisplay.contactId = contact.ContactID;
        contactDisplay.firstName = contact.FirstName;
        contactDisplay.lastName = contact.LastName;
        contactDisplay.emailAddress = contact.EmailAddress;
        contactDisplay.linkedInId = contact.LinkedInId;
        contactDisplay.contactOptedOutBool = contact.ContactOptedOut;
        contactDisplay.marketingEmailStatus = contact.EmailStatus;
        contactDisplay.marketingEmailOverrideStatus = contact.EmailOverrideStatus;
        contactDisplay.marketingOverrideDate = contact.OverrideDate;
        contactDisplay.marketingOverridePerson = contact.OverridePerson;
        contactDisplay.lastUser = contact.LastUser;
        contactDisplay.setOperation(OperationType.IDLE);

        const companiesContactInfoDisplay: CompaniesContactInfoDisplay = this.toCompaniesContactInfoDisplay(this.additionalInfo);
        companiesContactInfoDisplay.contactDisplay = contactDisplay;

        // We call to the contact entity info endpoint and wait for the response before opening the contact details.
        this.contactService.setContactEntityInfo(entityType, entityId, contactId, mvId, companiesContactInfoDisplay).subscribe(() => this.openContactDetails(companiesContactInfoDisplay));
    }

    openContactDetails(ccTemporal: CompaniesContactInfoDisplay) {
        const cmd: ContactModalData = {
            entityId: null,
            entityName: this.entityName,
            readOnly: true,
            saveEntityContactBtnEnabled: false,
            viewContactAssociationsEnabled: false,
            originalTitleId: '',
            contactGeneric: ccTemporal,
            contactMap: null,
            acContactsSlotted: []
        }

        const modalRef = this.dialogService.open(ContactDetailsModalComponent, {
            header: 'Contact Details',
            width: '90rem',
            data: cmd,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe();
    }

    toCompaniesContactInfoDisplay(contact: Contact) {
        const contactEntities = CompaniesContactInfoDisplay.CreateInstance({}, this.serverDate.now());

        contactEntities.contactId = contact.ContactID;
        contactEntities.companyId = contact.CompanyID;
        contactEntities.companyName = contact.CompanyName;
        contactEntities.companyStatus = contact.CompanyStatus;
        contactEntities.contactStatus = contact.ContactStatus ?? ContactStatusValue.Current[0];

        contactEntities.contactTitleDesc = contact.ActualTitle == null ? "" : contact.ActualTitle;
        if (contactEntities.contactTitleDesc == "") contactEntities.contactTitleDesc = contactEntities.titleDesc;
        contactEntities.mailAddressLine1 = contact.EntityAddressV1 == null ? "" : contact.EntityAddressV1;
        contactEntities.mailAddressLine2 = contact.EntityAddressV2 == null ? "" : contact.EntityAddressV2;

        contactEntities.mailCountry = contact.MailCountry == null ? "" : contact.MailCountry;
        contactEntities.mailState = contact.EntityState == null ? "" : contact.EntityState;
        contactEntities.mailCity = contact.EntityCity == null ? "" : contact.EntityCity;

        contactEntities.contactPhoneCc = contact.EntityPhoneCC;
        contactEntities.contactPhoneNo = contact.EntityPhoneNO == null ? "" : contact.EntityPhoneNO;
        contactEntities.contactPhoneExt = contact.EntityPhoneEXT == null ? "" : contact.EntityPhoneEXT;
        contactEntities.contactPhoneMobileSel = contact.EntityPhoneMobile == '1' ? true : false;

        contactEntities.contactAltPhoneCc = contact.EntityAltPhoneCC;
        contactEntities.contactAltPhoneNo = contact.EntityAltPhoneNO == null ? "" : contact.EntityAltPhoneNO;
        contactEntities.contactAltPhoneExt = contact.EntityAltPhoneEXT == null ? "" : contact.EntityAltPhoneEXT;
        contactEntities.contactAltPhoneMobileSel = contact.EntityAltPhoneMobile == '1' ? true : false;

        contactEntities.contactFaxCc = contact.FaxCC;
        contactEntities.contactFaxNo = contact.FaxNO == null ? "" : contact.FaxNO;
        contactEntities.contactFaxExt = contact.FaxEXT == null ? "" : contact.FaxEXT;

        contactEntities.mailPostalCode = contact.MailPostalCode == null ? "" : contact.MailPostalCode;
        contactEntities.mailStateName = contact.StateName == null ? "" : contact.StateName;
        contactEntities.mailCountryName = contact.Country_Name == null ? "" : contact.Country_Name;

        contactEntities.contactTitle = contact.ContactTitleId;
        contactEntities.titleDesc = contact.ContactTitleDesc;
        contactEntities.contactFunction = contact.ContactFunction;
        contactEntities.operationInPool = contact.Operation;

        if (contact.QcDate != null) {
            contactEntities.contactQcDate = String(contact.QcDateObj.getTime());
        }

        contactEntities.mailCountryId = contact.MailCountryCID;
        contactEntities.mailStateId = contact.MailStateSID;
        contactEntities.mailCityId = contact.MailCityCIID;

        contactEntities.contactLastUser = contact.LastUser;
        contactEntities.setOperation(OperationType.IDLE);

        return contactEntities;
    }

    openNewContactModal() {
        let inputValues = this.contactModalForm.get('InputsFilters').value;
        this.contactService.contactNextId().subscribe((contact: any) => {
            if (contact !== undefined) {
                if (inputValues !== undefined) {
                    contact.firstName = inputValues.FirstName;
                    contact.lastName = inputValues.LastName;
                    this.activeModal.close(contact)
                } else {
                    this.activeModal.close(contact)
                }
            }
        });

    }
    // Enabled button new contact and view contact-details
    showButton() {
        this.disableButton = !this.disableButton;
    }

    enabledBtnNewContact() {
        if (this.allowNewContacts) {
            this.disableBtnNewContact = false;
        }
    }

    search(srcElement: any = undefined) {
        this.getContacts(srcElement);
    }

    private getContacts(srcElement: any) {
        let applyCoverage = false;
        let onSite = false;
        if (this.contactModalForm.get('InputsFilters.CompanyName').value != '' ||
            this.contactModalForm.get('InputsFilters.FirstName').value != '' ||
            this.contactModalForm.get('InputsFilters.LastName').value != '' ||
            this.contactModalForm.get('InputsFilters.ContactID').value != '' ||
            this.contactModalForm.get('InputsFilters.Email').value != '') {

            applyCoverage = true;
            onSite = true;
        }

        this.contactService.searchAllContact(this.contactModalForm.get('InputsFilters').value,
            this.contactModalForm.controls.OptionsFilters.value.Filter, applyCoverage, onSite, srcElement)
            .subscribe((contact: any) => {
                this.additionalInfo = null;
                this.companyContacts = contact;
                if (this.companyContacts.length > 0) {
                    this.disableButton = false;
                    this.selectFirstElement();
                }
                if (this.contactModalForm.get('InputsFilters').value) {
                    this.enabledBtnNewContact();
                }
                this.resetTable();
            });
    }

    clear() {
        this.buildForm();
        this.disableButton = true;
        this.companyContacts = [];
        this.additionalInfo = null;
    }

    getRowClass(row: Contact) {
        let rowClass = '';
        if (this.isDisabledRow(row)) {
            rowClass = 'unavailable';
        } else if (row.ContactOptedOut) {
            rowClass = 'optedout';
        }

        return rowClass;
    }

    isDisabledRow(row: Contact) {
        return this.contactService.getDisabledRow(row, this.entityName);
    }

    getColumnTitle(row: Contact) {
        const entityRecordStatusMsg = this.getEntityRecordStatusMessage(row);
        if (entityRecordStatusMsg) {
            return entityRecordStatusMsg;
        }

        const contactStatusTitle = this.getContactStatusTitle(row);
        if (contactStatusTitle) {
            return contactStatusTitle;
        }

        if (this.entityName === EntityName.PROJECT && row.isAddressMissing()) {
            return this.translate.instant('contact.contactModal.noSelectionMissingAddress');
        }

        const companyStatusDesc = this.getStatusDescription(row.CompanyStatus, [CompanyStatusValue.Closed, CompanyStatusValue.SuspendedUnresolved]);
        const recordStatusDesc = this.getStatusDescription(row.RecordStatus, [RecordStatusValue.Trash, RecordStatusValue.Incomplete]);

        if (this.entityName === EntityName.PROJECT) {
            const statusDesc = this.getStatusDescription(row.RecordStatus, [RecordStatusValue.Incomplete]);
            return this.getProjectTitle(row, statusDesc || companyStatusDesc || recordStatusDesc);
        }

        return '';
    }

    private getEntityRecordStatusMessage(row: Contact): string {
        const entityRelation = row.EntityRelation;
        if (
            StringUtils.equalsIgnoreCase(row.EntityRecordStatus, RecordStatusValue.Trash) &&
            (this.entityName === EntityName.SITE ||
                ([EntityName.PLANT, EntityName.AREA, EntityName.UNIT, EntityName.PLANT_LTSA, EntityName.PIPELINE_LTSA] as string[]).includes(entityRelation))
        ) {
            return this.translate.instant('contact.contactModal.noSelectionEntityStatus', { letter: ([EntityName.AREA, EntityName.UNIT] as string[]).includes(row.EntityRelation) ? 'an' : 'a' })
                .replace('{{entity}}', entityRelation);
        }
        return '';
    }

    private getContactStatusTitle(row: Contact): string {
        if (
            (!StringUtils.equalsIgnoreCase(row.ContactStatus, ContactStatus.CURRENT) && this.entityName === EntityName.PROJECT) ||
            StringUtils.equalsIgnoreCase(row.ContactStatus, ContactStatus.DEAD) ||
            StringUtils.equalsIgnoreCase(row.ContactStatus, ContactStatus.TRASH)
        ) {
            return this.translate.instant('contact.contactModal.noSelectionContactStatus', { status: this.contactStatuses[row.ContactStatus] });
        }
        return '';
    }

    private getStatusDescription(status: string, validStatusValues: string[]): string {
        if (validStatusValues.includes(status)) {
            switch (status) {
                case CompanyStatusValue.Closed:
                    return this.closed;
                case CompanyStatusValue.SuspendedUnresolved:
                    return this.suspendedUnresolved;
                case RecordStatusValue.Trash:
                    return this.trashStatus;
                case RecordStatusValue.Incomplete:
                    return this.incompleteStatus;
                default:
                    return '';
            }
        }
        return '';
    }

    private getProjectTitle(row: Contact, statusDesc: string): string {
        if (statusDesc) {
            const companyMsg = this.translate.instant('contact.contactModal.noSelectionCompanyStatus', { letter: 'a' });
            return companyMsg.replace('{{status}}', statusDesc);
        }
        return '';
    }

    private buildForm() {
        this.contactModalForm = this.fb.group({
            OptionsFilters: this.OptionsFilters(),
            InputsFilters: this.InputsFilters()
        });
    }

    private OptionsFilters(): FormGroup {
        return this.fb.group({
            Filter: ['All', []],
        });
    }

    private InputsFilters(): FormGroup {
        return this.fb.group({
            FirstName: [''],
            LastName: [''],
            ContactID: [''],
            CompanyName: [''],
            Email: ['']
        })
    }
 
}
