import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { Address } from 'src/app/core/models';
import { Constants, ContactStatusValue, EntityPreferenceSufix, MailingAddressesStatus, PreferencePrefix } from 'src/app/core/models/constants';
import { ContactStatus } from 'src/app/core/models/enumerations/contact-status';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { CsvData, CsvHeaderItem, CsvService } from 'src/app/core/services/csv.service';
import { LockSameUserMessageService } from 'src/app/core/services/lock-same-user-message.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityPaths } from 'src/app/core/utils/entityPaths';
import { ContactDetailsSectionService } from '../../contact-details-section.service';
import { ContactRelatedEntities } from '../contact-details-modal.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { StringUtils } from 'src/app/core/utils/string-utils';

export class ContactRelatedResponse {
    type: ContactRelatedEntities;
    address: Address;
    nativeRow: any;

    constructor(type: ContactRelatedEntities, address: Address, nativeRow: any) {
        this.type = type;
        this.address = address;
        this.nativeRow = nativeRow;
    }
}

@Component({
    selector: 'app-contact-related',
    templateUrl: './contact-related.component.html',
    styleUrls: ['./contact-related.component.scss']
})

export class ContactRelatedComponent implements OnInit {

    @ViewChild('createCsvBtn') createCsvBtn: ElementRef;
    @ViewChildren('tabIndexSetted') tabIndexSetted: QueryList<any>;
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;

    type: ContactRelatedEntities;
    mainEntityId: number;
    mainEntityName: string;
    selfMvId: number;
    contactId: number;
    contactName: string;
    contactOptedOut: boolean;
    contactProvider: Array<any>;
    onSite: boolean;
    isContactMailingAddress: boolean;
    isContactAssociations: boolean;
    statusChange: boolean;
    cols: any = [];
    rowsPerPage: number;
    tableKey: string = EntityPreferenceSufix.Entity;
    entitiesWithContacts: Array<string> = [EntityName.COMPANY, EntityName.PLANT, EntityName.ASSET_PIPELINE, EntityName.AREA,
    EntityName.ASSET_TRANSMISSION_LINE, EntityName.SITE, EntityName.PROJECT_CONFIRMED, EntityName.PROJECT_UNCONFIRMED, EntityName.UNIT];
    public get ContactRelatedEntities(): typeof ContactRelatedEntities {
        return ContactRelatedEntities;
    }

    constructor(
        private activeModel: DynamicDialogRef,
        private contactService: ContactDetailsSectionService,
        private csvService: CsvService,
        private translate: TranslateService,
        private dinamicDialogConfig: DynamicDialogConfig,
        private sharedService: SharedService,
        private authService: AuthService,
        public preferencesService: PreferencesSectionService,
        private lockSameUserMessageService: LockSameUserMessageService
    ) { }

    ngOnInit(): void {
        this.rowsPerPage = this.preferencesService.getCommonRowsPerPage(PreferencePrefix.Search+this.tableKey);
        const cmd = this.dinamicDialogConfig.data;
        this.mainEntityId = cmd.mainEntityId;
        this.mainEntityName = cmd.mainEntityName;
        this.selfMvId = cmd.selfMvId;
        this.contactId = cmd.contactId;
        this.contactName = cmd.contactName;
        this.contactOptedOut = cmd.contactOptedOut;
        this.contactProvider = cmd.contactProvider;
        this.statusChange = cmd.statusChange || false;
        this.type = cmd.type;
        this.isContactMailingAddress = this.type === ContactRelatedEntities.CONTACT_MAILING_ADDRESS;
        this.isContactAssociations = this.type === ContactRelatedEntities.CONTACT_ASSOCIATIONS;
        this.setRowStatus();
        this.initColumns();
    }

    onPageChange(event): void {
        this.preferencesService.updateCommonPreferences(PreferencePrefix.Search+this.tableKey, StringUtils.toStringNeverNull(event.rows));
    }

    initColumns(): void {
        this.cols = [
            { field: 'ENTITY_TYPE', header: this.translate.instant('common.entity'), isDate: false, isLink: false },
        ];
        if (this.isContactAssociations) {
            this.cols.push({ field: 'ENTITY_ID', header: this.translate.instant('common.entityId'), isDate: false, isLink: true });
        } else {
            this.cols.push({ field: 'ENTITY_ID', header: this.translate.instant('common.entityId'), isDate: false, isLink: false });
        }
        this.cols.push({ field: 'ENTITY_STATUS', header: this.translate.instant('common.entityStatus'), isDate: false, isLink: false });
        this.cols.push({ field: 'CONTACT_STATUS', header: this.translate.instant('contact.contactRelated.contactStatus'), isDate: false, isLink: false });
        if (this.isContactAssociations) {
            this.cols.push({ field: 'TITLE_DESC', header: this.translate.instant('contact.contactRelated.functionalProjectTitle'), isDate: false, isLink: false });
        }
        this.cols.push({ field: 'COUNTRY_NAME', header: this.translate.instant('common.country'), isDate: false, isLink: false });
        if (this.isContactMailingAddress) {
            this.cols.push({ field: 'POSTAL_CODE', header: this.translate.instant('common.postalCode'), isDate: false, isLink: false });
            this.cols.push({ field: 'ADDRESS_V1', header: this.translate.instant('common.address'), isDate: false, isLink: false });
        }
        this.cols.push({ field: 'STATE_NAME', header: this.translate.instant('common.state'), isDate: false, isLink: false });
        this.cols.push({ field: 'CITY', header: this.translate.instant('common.city'), isDate: false, isLink: false });
        this.cols.push({ field: 'CONTACT_QC_DATE_OBJ', header: this.translate.instant('contact.contactRelated.contactQc'), isDate: true, isLink: false });
    }

    dgContact_doubleClick(item: any) {
        if (!item.disabled) {
            if (this.type === ContactRelatedEntities.CONTACT_MAILING_ADDRESS) {
                const address = Address.BuildContactFromSearch(item);
                this.activeModel.close(new ContactRelatedResponse(this.type, address, item));
            } else if (this.type === ContactRelatedEntities.CONTACT_ASSOCIATIONS && item.available) {
                this.entityContactLink(item);
            }
        }
    }

    entityContactLink(item: any) {
        const entityType = item.ENTITY_TYPE;
        const entityID = item.ENTITY_ID;
        let entityPath = EntityPaths[Object.keys(EntityName).find(key => EntityName[key] === entityType)];
        this.lockSameUserMessageService.validateEntityIsLockedByUserAndOpenEntityTab(entityType, entityID, location.pathname + `#/ED/${entityPath}/${entityID}`, `${entityType + entityID}`);
    }

    reload() {
        let onSite = false;
        this.contactProvider = [];
        if (this.type === ContactRelatedEntities.CONTACT_ASSOCIATIONS) {
            onSite = true;
        }
        this.contactService.getSearchAllContacts(this.selfMvId, '', '', '', '', this.contactId, 0, 'Associations', false, onSite).subscribe(resp => {
            this.contactProvider = resp;
            this.setRowStatus();
        });
    }

    downloadCsv() {
        if (this.contactProvider.length > 0) {
            const title = 'Contact Id: ' + this.contactId;
            const subtitle = 'Contact Name: ' + this.contactName;
            const headers: Array<CsvHeaderItem> = [
                new CsvHeaderItem('ENTITY_TYPE', 'Entity'),
                new CsvHeaderItem('ENTITY_ID', 'Entity ID'),
                new CsvHeaderItem('ENTITY_STATUS', 'Entity Status'),
                new CsvHeaderItem('CONTACT_STATUS', 'Contact Status'),
                new CsvHeaderItem('TITLE_DESC', 'Functional Title/Project Actual Title'),
                new CsvHeaderItem('COUNTRY_NAME', 'Country'),
                new CsvHeaderItem('STATE_NAME', 'State'),
                new CsvHeaderItem('CITY', 'City'),
                new CsvHeaderItem('CONTACT_QC_DATE_OBJ', 'Contact QC Date'),
            ];
            const csvData = new CsvData(title, subtitle, headers, this.contactProvider);
            this.csvService.exportToCsv(`Contact${this.contactId}.csv`, csvData);
        }
    }

    setRowStatus() {
        if (this.isContactMailingAddress) {
            this.contactProvider.forEach(item => {
                item.available = this.isMailingAddressAvailable(item.CONTACT_STATUS, item.ENTITY_TYPE, item.ENTITY_STATUS);
                item.disabled = !item.available;
                item.tooltip = this.getMailingAddressMessage(item.CONTACT_STATUS, item.ENTITY_TYPE, item.ENTITY_STATUS);
            });
        } else {
            this.contactProvider.forEach(item => {
                this.authService.hasUserScreen(item.ENTITY_TYPE).subscribe(b => item.disabled = !b);
                item.available = this.entitiesWithContacts.some(ent => ent === item.ENTITY_TYPE);
            });
        }
    }


    isMailingAddressAvailable(contactStatus: string, entityType: string, entityStatus: string) {
        return (contactStatus.toUpperCase() === ContactStatusValue.Dead ||
            contactStatus.toUpperCase() === ContactStatusValue.Trash ||
            contactStatus.toUpperCase() === ContactStatusValue.Moved ||
            contactStatus.toUpperCase() === ContactStatusValue.Retired ||
            ((entityType.toUpperCase() === EntityName.COMPANY.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.SuspendedUnresolved)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === ContactStatusValue.Dead)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase() || entityType.toUpperCase() === EntityName.COMPANY.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Closed)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Engineered)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Cancelled)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Planned)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Lead)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.OnHold)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Removed)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() === EntityName.PLANT.toUpperCase() || entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.UnResolved)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Dismantled)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.NonManufacturing)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.ClosedForSale)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.ClosedSite)) ||
            ((entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Killed))) ? false : true;
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    getMailingAddressMessage(contactStatus: string, entityType: string, entityStatus: string): string {

        let letterStatus = entityStatus && entityStatus.length > 0 && (/[aeiouAEIOU]/i).test(entityStatus.substring(0, 1)) ? 'an' : 'a';
        if (contactStatus.toUpperCase() !== "NULL" && contactStatus.toUpperCase() !== ContactStatus.CURRENT) {
            return this.translate.instant('contact.contactRelated.toolTipContactMailingAddressCannotSelectionItem', { status: contactStatus });
        } else if (
            ((entityType.toUpperCase() === EntityName.COMPANY.toUpperCase() || entityType.toUpperCase() === EntityName.SITE.toUpperCase()) &&
                (entityStatus.toUpperCase() === MailingAddressesStatus.SuspendedUnresolved)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() === ContactStatusValue.Dead)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Dismantled)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.NonManufacturing)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.ClosedForSale)) ||
            ((entityType.toUpperCase() === EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.ClosedSite)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase() || entityType.toUpperCase() == EntityName.COMPANY.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Closed)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.SITE.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Engineered)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Cancelled)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.SITE.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Planned)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Lead)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.SITE.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.OnHold)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.SITE.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.Removed)) ||
            ((entityType.toUpperCase() === EntityName.UNIT.toUpperCase() || entityType.toUpperCase() == EntityName.PLANT.toUpperCase() || entityType.toUpperCase() == EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() == MailingAddressesStatus.UnResolved)) ||
            ((entityType.toUpperCase() === EntityName.AREA.toUpperCase()) && (entityStatus.toUpperCase() === MailingAddressesStatus.Killed))
        ) {
            return this.translate.instant('contact.contactRelated.toolTipContactEntityMailingAddressCannotSelectionItem', { entityType: entityType, letter: letterStatus, entityStatus: entityStatus });
        }
        return '';
    }

}
