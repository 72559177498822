import { Bean } from "../../bean";
import { Contacts } from "../../contact/save/contacts";
import { ProjectsBidder } from "./projects-bidder";
import { ProjectsCapacity } from "./projects-capacity";
import { ProjectsCompany } from "./projects-company";
import { ProjectsContractingNeeds } from "./projects-contracting-needs";
import { ProjectsDefinitiveKeyNeeds } from "./projects-definitive-keyneeds";
import { ProjectsEngineeringNeeds } from "./projects-engineering-needs";
import { ProjectsFaxInfo } from "./projects-fax-info";
import { ProjectsIndustryFlag } from "./projects-industry-flag";
import { ProjectsInternalAttachments } from "./projects-internal-attachments";
import { ProjectsKeyNeeds } from "./projects-key-needs";
import { ProjectsMarketTrends } from "./projects-market-trends";
import { ProjectsMatterStates } from "./projects-matter-states";
import { ProjectsNotes } from "./projects-notes";
import { ProjectsOfflineEvents } from "./projects-offline-events";
import { ProjectsPhoneInfo } from "./projects-phone-info";
import { ProjectsPipesInstallation } from "./projects-pipes-installation";
import { ProjectsUnitIds } from "./projects-unit-ids";

export class Projects extends Bean {
    projectId: number;
    projectName: string;
    plantId: number;
    umbrellaId: number;
    plantsName: string;

    physPostalCode: string;
    ownerProject: string;
    ownerSicCode: string;
    projectTiv: number;
    lastCompany: string;
    lastUser: string;
    lastDate: string;
    prevCompany: string;
    prevUser: string;
    prevDate: string;
    entryCompany: string;
    entryUser: string;
    entryDate: string;
    pecActivity: string;
    reviewDate: string;
    duration: number;
    afeDate: number;
    rfqDate: number;
    bidDocDate: number;
    kickoffDate: number;
    completionDate: string;
    longLeadDate: number;
    completionDateResolution: string;
    industryCode: string;
    status: string;
    lastTime: string;
    qcDate: string;
    qcUser: string;
    qcTime: string;
    envAir: string;
    envLand: string;
    envWater: string;
    entryTime: string;
    buyElect: number;
    buyFuel: number;
    buySteam: number;
    sellElect: number;
    sellFuel: number;
    sellSteam: number;
    hsProductId: string;
    outputCapacity: number;
    outputCapacityUnit: string;
    timeMeasure: string;
    storageCapacity: string;
    storageCapacityUnit: string;
    laborUnion: string;
    laborMerit: number;
    scopeText: string;
    scheduleText: string;
    buildingNewSqft: number;
    buildingRetRenSqft: number;
    projectProductSicCode: string;
    releaseDate: string;
    releaseUser: string;
    liveDate: string;
    author: number;
    contributingAuthor: number;
    unitName: string;
    projectTivCurrency: string;
    projectRecordStatus: string;
    projectStatusReason: string;
    amendmentDate: string;
    amendmentUser: string;
    tivQcDate: string;
    scopeQcDate: string;
    scheduleQcDate: string;
    tivQcUser: string;
    scopeQcUser: string;
    scheduleQcUser: string;
    projectFuelType: string;
    projectType: string;
    initiallyUnconfirmed: number;
    confirmedDate: string;
    restart: number;
    restartDate: string;
    projectSource: string;

    physCountryId: number;
    physStateId: number;
    physCityId: number;
    physCountyId: number;

    estimatedKickoffDate: number;
    peakWorkers: number;
    peakWorkersDate: number;
    projectProbabilityId: string;
    shiftsDay: number;
    shiftLength: number;

    projectsNotes: Array<ProjectsNotes>
    projectsKeyNeeds: Array<ProjectsKeyNeeds>
    projectsPhone: Array<ProjectsPhoneInfo>;
    projectsCompany: Array<ProjectsCompany>;
    projectsContractingNeeds: Array<ProjectsContractingNeeds>;
    projectsDefinitiveKeyNeeds: Array<ProjectsDefinitiveKeyNeeds>;
    projectsEngineeringNeeds: Array<ProjectsEngineeringNeeds>;
    projectsFax: Array<ProjectsFaxInfo>;
    projectsIndustryFlag: Array<ProjectsIndustryFlag>;
    projectsMatterStates: Array<ProjectsMatterStates>;
    projectsUnitIds: Array<ProjectsUnitIds>;
    projectsInternalAttachments: Array<ProjectsInternalAttachments>;
    projectsOfflineEvents: Array<ProjectsOfflineEvents>;
    projectsBidder: Array<ProjectsBidder>;
    projectsCapacity: Array<ProjectsCapacity>;
    projectsPipesInstallation: Array<ProjectsPipesInstallation>;

    contacts: Array<Contacts>;
    projectsMarketTrends: Array<ProjectsMarketTrends>;


    protected constructor(entity?: any) {
        super(entity);

        this.projectId = entity?.projectId ?? null;
        this.projectName = entity?.projectName ?? null;
        this.plantId = entity?.plantId ?? null;
        this.umbrellaId = entity?.umbrellaId ?? null;
        this.plantsName = entity?.plantsName ?? null;

        this.physPostalCode = entity?.physPostalCode ?? null;
        this.ownerProject = entity?.ownerProject ?? null;
        this.ownerSicCode = entity?.ownerSicCode ?? null;
        this.projectTiv = entity?.projectTiv ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.pecActivity = entity?.pecActivity ?? null;
        this.reviewDate = entity?.reviewDate ?? null;
        this.duration = entity?.duration ?? null;
        this.afeDate = entity?.afeDate ?? null;
        this.rfqDate = entity?.rfqDate ?? null;
        this.bidDocDate = entity?.bidDocDate ?? null;
        this.kickoffDate = entity?.kickoffDate ?? null;
        this.completionDate = entity?.completionDate ?? null;
        this.longLeadDate = entity?.longLeadDate ?? null;
        this.completionDateResolution = entity?.completionDateResolution ?? null; 
        this.industryCode = entity?.industryCode ?? null;
        this.status = entity?.status ?? null;
        this.lastTime = entity?.lastTime ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcTime = entity?.qcTime ?? null;
        this.envAir = entity?.envAir ?? null;
        this.envLand = entity?.envLand ?? null;
        this.envWater = entity?.envWater ?? null;
        this.entryTime = entity?.entryTime ?? null;
        this.buyElect = entity?.buyElect ?? null;
        this.buyFuel = entity?.buyFuel ?? null;
        this.buySteam = entity?.buySteam ?? null;
        this.sellElect = entity?.sellElect ?? null;
        this.sellFuel = entity?.sellFuel ?? null;
        this.sellSteam = entity?.sellSteam ?? null;
        this.hsProductId = entity?.hsProductId ?? null;
        this.outputCapacity = entity?.outputCapacity ?? null;
        this.outputCapacityUnit = entity?.outputCapacityUnit ?? null;
        this.timeMeasure = entity?.timeMeasure ?? null;
        this.storageCapacity = entity?.storageCapacity ?? null;
        this.storageCapacityUnit = entity?.storageCapacityUnit ?? null;
        this.laborUnion = entity?.laborUnion === 0 ? entity.laborUnion : null;
        this.laborMerit = entity?.laborMerit ?? null;
        this.scopeText = entity?.scopeText ?? null;
        this.scheduleText = entity?.scheduleText ?? null;
        this.buildingNewSqft = entity?.buildingNewSqft ?? null;
        this.buildingRetRenSqft = entity?.buildingRetRenSqft ?? null;
        this.projectProductSicCode = entity?.projectProductSicCode ?? '';
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.author = entity?.author === 0 ? entity.author : null;
        this.contributingAuthor = entity?.contributingAuthor ?? null;
        this.unitName = entity?.unitName ?? null;
        this.projectTivCurrency = entity?.projectTivCurrency ?? null;
        this.projectRecordStatus = entity?.projectRecordStatus !== undefined ? String(entity.projectRecordStatus) : null;
        this.projectStatusReason = entity?.projectStatusReason ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.tivQcDate = entity?.tivQcDate ?? null;
        this.scopeQcDate = entity?.scopeQcDate ?? null;
        this.scheduleQcDate = entity?.scheduleQcDate ?? null;
        this.tivQcUser = entity?.tivQcUser ?? null;
        this.scopeQcUser = entity?.scopeQcUser ?? null;
        this.scheduleQcUser = entity?.scheduleQcUser ?? null;
        this.projectFuelType = entity?.projectFuelType ?? null;
        this.projectType = entity?.projectType !== undefined ? String(entity.projectType) : null;
        this.initiallyUnconfirmed = entity?.initiallyUnconfirmed ?? null;
        this.confirmedDate = entity?.confirmedDate ?? null;
        this.restart = entity?.restart ?? 0;
        this.restartDate = entity?.restartDate ?? null;
        this.projectSource = entity?.projectSource !== undefined ? String(entity.projectSource) : null;

        this.physCountryId = entity?.physCountryId ?? null;
        this.physStateId = entity?.physStateId ?? null;
        this.physCityId = entity?.physCityId ?? null;
        this.physCountyId = entity?.physCountyId ?? null;

        this.estimatedKickoffDate = entity?.estimatedKickoffDate ?? null;
        this.peakWorkers = entity?.peakWorkers ?? null;
        this.peakWorkersDate = entity?.peakWorkersDate ?? null;
        this.projectProbabilityId = entity?.projectProbabilityId ?? null;
        this.shiftsDay = entity?.shiftsDay ?? null;
        this.shiftLength = entity?.shiftLength ?? null;
    }
}
