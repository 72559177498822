 <div class="container-modal">
    <div class="content-modal">
        <div>
            <p-tabView (onChange)="switchPanel($event)">
                <p-tabPanel header="Details View">
                    <div class="container-form">

                        <form [formGroup]="entityContactFormGroup">
                        <!-- ********** START CONTACTS INFORMATION SECTION ********** -->

                                <app-form-contact-details #contactData    
                                    [newContactId]="newContactId"
                                    [contactGeneric]="contactGeneric"
                                    [entityName]="entityName"
                                    [entityId]="entityId"
                                    [locked]="contactDisplayLocked"
                                    (updateContactDisplayLocked)="updateContactDisplayLocked($event)">
                                </app-form-contact-details>

                        <!-- ********** END CONTACTS INFORMATION SECTION ********** -->

                        <!-- ********** START ENTITY CONTACTS INFORMATION SECTION ********** -->
                        <div class="grid mt-1">
                            <div class="col-12 xl:col-6 mt-1">
                                <!-- Company -->
                                <div class="grid">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.company'| translate}}:</label>
                                    <div class="col-6 px-1">
                                        <input
                                            type="text"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-4"
                                            (keyup.enter)="companyIdOnEnter()"
                                            (keydown.Tab)="companyIdOnTab($event)"
                                            (blur)="companyIdOnBlur()"
                                            formControlName="companyId"
                                            [readonly]="!editableCompany"
                                        >
                                        <input
                                            type="text"
                                            pInputText
                                            class="form__input-field-style w-8"
                                            formControlName="companyName"
                                            tabindex="-1"
                                        >
                                    </div>
                                    <div class="col-2 px-0">
                                        <label type="text"
                                            class="required-field" pTooltip="This field is required" tooltipPosition="right">*
                                        </label>
                                        <button
                                            *ngIf="editableCompany"
                                            pButton
                                            pRipple
                                            type="button"
                                            icon="pi pi-search"
                                            class="pd p-button-info p-button-text pe-auto"
                                            (click)="openCompanySearchModal()">
                                        </button>
                                    </div>
                                </div>
                                <!-- Status -->
                                <div class="grid">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.status' | translate}}:</label>
                                    <div class="col-6 px-1">
                                        <p-dropdown
                                            [options]="status"
                                            optionValue="data"
                                            optionLabel="label"
                                            [filter]="true"
                                            showClear="true"
                                            [placeholder]="'common.selectOne' | translate"
                                            (onChange)="onChangeStatus($event)"
                                            class="form__container-fields-layout--selects"
                                            formControlName="contactStatus">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-2 px-0">
                                        <label type="text"
                                            class="required-field" pTooltip="This field is required" tooltipPosition="right">*
                                        </label>
                                    </div>
                                </div>
                                <!-- OnSite -->
                                <div class="grid flex">
                                    <div class="col-4 justify-content-end text-right px-0">
                                        <p-checkbox
                                            class="text-xs font-bold labelFirst boxSizeCompensate" 
                                            formControlName="contactOnsiteBool"
                                            [binary]="true" (click)="onClickSiteVerified()"
                                            label="{{'contact.common.onSite' | translate}}:"
                                        >
                                        </p-checkbox>
                                    </div>
                                    <div class="col-1 justify-content-end px-1"></div>
                                    <div class="col-5 justify-content-end text-right px-0">
                                        <p-checkbox
                                            class="text-xs font-bold labelFirst" 
                                            formControlName="contactSaleFlagBool"
                                            [binary]="true"
                                            label="{{'contact.common.forSale' | translate}}:"
                                        >
                                        </p-checkbox>
                                    </div>
                                    <div class="col-2 px-0"></div>
                                </div>
                                <!-- Btn -->
                                <div class="grid">
                                    <div class="col-1"></div>
                                    <div class="col-8">
                                        <button class="btn-row w-10rem h-1-5rem" type="button" (click)="openContactMailingAddresses()" [disabled]="existingAddressButtonDisabled">{{'contact.common.locateExistingAddresses' |translate}}</button>
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                            </div>

                            <div class="col-12 xl:col-6">
                                <!-- Last User -->
                                <div id="lastUserContainer" class="grid text-xs">
                                    <div class="col-4 text-right">
                                        <label class="font-bold">{{'contact.common.lastUser'|translate}}:</label>
                                    </div>
                                    <div class="col-3 text-left px-0">
                                        <label *ngIf="contactGeneric !== undefined && !displayLink(contactGeneric.iCopy?.lastUserEmailAddress)" for="">{{ contactGeneric.iCopy?.contactLastUser }}</label>
                                        <a *ngIf="contactGeneric !== undefined && displayLink(contactGeneric.iCopy?.lastUserEmailAddress)" (click)="openUserModal(contactGeneric.iCopy?.contactLastUser)" class="basic-link label-user cursor-pointer">{{ contactGeneric.iCopy?.contactLastUser }}</a>
                                    </div>
                                    <div class="col-5 text-left">
                                        <label  *ngIf="contactGeneric !== undefined">{{ contactGeneric.iCopy?.contactLastDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                                    </div>
                                </div>
                                <!-- Title Query -->
                                <div class="grid mt-2">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.titleQuery' | translate}}:</label>
                                    <div class="col-6 px-1">
                                        <input
                                            #inputTitleQuery
                                            type="text"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-6"
                                            formControlName="contactTitle"
                                            (focusout)="titleChange()"
                                            (keyup.enter)="titleEnter()">
                                        <input
                                            type="text"
                                            pInputText
                                            class="form__input-field-style w-6"
                                            formControlName="titleDesc"
                                            tabindex="-1">
                                    </div>
                                    <div class="col-2 px-0">
                                        <label
                                            type="text"
                                            class="required-field pt-1"
                                            pTooltip="This field is required"
                                            tooltipPosition="right">*
                                        </label>
                                        <button
                                            pButton
                                            pRipple
                                            type="button"
                                            icon="pi pi-search"
                                            class="pd p-button-info p-button-text pe-auto"
                                            [disabled]="disabledSaveRecordButton"
                                            (click)="openTitleQuerySearch()">
                                        </button>
                                    </div>
                                </div>
                                <!-- Job Function -->
                                <div class="grid">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.jobFunction'  | translate}}:</label>
                                    <div class="col-6 px-1">
                                        <p-dropdown
                                            [options]="jobFunctions"
                                            optionValue="functionId"
                                            [filter]="true"
                                            showClear="true"
                                            [placeholder]="'common.selectOne' | translate"
                                            optionLabel="functionConcat"
                                            class="form__container-fields-layout--selects"
                                            formControlName="contactFunction">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-2">
                                    </div>
                                </div>
                                <!-- Actual Title -->
                                <div class="grid">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.actualTitle'| translate}}:</label>
                                    <div class="col-6 px-1">
                                        <input
                                            type="text"
                                            pInputText
                                            class="form__input-field-style w-full"
                                            formControlName="contactTitleDesc"
                                        >
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                            </div>
                        </div>

                        <!-- Low Level Section -->
                        <div class="grid">
                            <!-- ADDRESS -->
                            <div class="col-12 xl:col-6 mt-1">
                                <app-address-form #contactAddress
                                [addressType]="2"
                                [showPecZone]="false"
                                [showChkSameAsPhys]="false"
                                [showInContacts]="true"
                                [changedFieldsReMap]="contactAddressChangedFieldsReMap"
                                [onSite]="this.entityContactFormGroup.controls.contactOnsiteBool!.value">
                                </app-address-form>
                            </div>
                            <!-- TELEPHONES -->
                            <div class="col-12 xl:col-6 mt-1">
                                <!-- Mobile -->
                                <div class="grid">
                                    <div class="col-10"></div>
                                    <label class="col-2 text-xs font-bold -ml-1">{{'common.mobile' | translate}}:</label>
                                </div>
                                <!-- Phone -->
                                <div class="grid">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.phone' | translate}}:</label>
                                    <div class="col-6 px-1">
                                        <input
                                            type="text"
                                            maxlength="3"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-2"
                                            formControlName="contactPhoneCc"
                                            [style.color]="colors['contactPhoneCc']"
                                        >
                                        <input
                                            type="text"
                                            maxlength="14"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-7"
                                            formControlName="contactPhoneNo"
                                            [style.color]="colors['contactPhoneNo']"
                                        >
                                        <input
                                            type="text"
                                            maxlength="7"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-3"
                                            formControlName="contactPhoneExt"
                                            [style.color]="colors['contactPhoneExt']"
                                        >
                                    </div>
                                    <div class="col-2 -mt-1">
                                        <img src="" class="mb-img mt-2 w-1rem -ml-2 selectable-none opacity-0" width="15px" height="15px">
                                        <p-checkbox class="ml-1 pb-0"
                                        formControlName="contactPhoneMobileSel"
                                            [binary]="true">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <!-- Alt Phone -->
                                <div class="grid" *ngIf="hasFaxAndAltPhone()">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.altPhone' | translate}}:</label>
                                    <div class="col-6 px-1">
                                        <input type="text"
                                            maxlength="3"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-2"
                                            formControlName="contactAltPhoneCc"
                                            [style.color]="colors['contactAltPhoneCc']">
                                        <input type="text"
                                            maxlength="14"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-7"
                                            formControlName="contactAltPhoneNo"
                                            [style.color]="colors['contactAltPhoneNo']">
                                        <input type="text"
                                            maxlength="7"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-3"
                                            formControlName="contactAltPhoneExt"
                                            [style.color]="colors['contactAltPhoneExt']">
                                    </div>
                                    <div class="col-2 -mt-1">
                                        <img src="assets/icons/icon_info.png" class="mb-img mt-2 w-1rem -ml-2" alt="info" pTooltip="Alt phone can be a direct dial line, mobile phone, internet phone, etc." width="15px" height="15px">
                                        <p-checkbox class="ml-1 pb-0"
                                            formControlName="contactAltPhoneMobileSel"
                                            [binary]="true">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <!-- Fax -->
                                <div class="grid" *ngIf="hasFaxAndAltPhone()">
                                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.fax'| translate}}:</label>
                                   <div class="col-6 px-1">
                                        <input
                                            type="text"
                                            maxlength="3"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-2"
                                            formControlName="contactFaxCc"
                                            [style.color]="colors['contactFaxCc']"
                                        >
                                        <input
                                            type="text"
                                            maxlength="14"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-7"
                                            formControlName="contactFaxNo"
                                            [style.color]="colors['contactFaxNo']"
                                        >
                                        <input
                                            type="text"
                                            maxlength="7"
                                            pInputText
                                            appOnlyNumbers
                                            class="form__input-field-style w-3"
                                            formControlName="contactFaxExt"
                                            [style.color]="colors['contactFaxExt']"
                                        >
                                    </div>
                                    <div class="col-2 px-0"></div>
                                </div>
                                <!-- Qc Date -->
                                <div class="grid">
                                    <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.qcDate'| translate}}:</label>
                                        <div class="col-4 xl:col-3 px-1">
                                            <div class="text-left">
                                                <p-calendar
                                                    #qcDate
                                                    class="contact-qc-date"
                                                    clearButtonStyleClass="calendarNoClear"
                                                    formControlName="contactQcDate"
                                                    [showButtonBar]="true"
                                                    [readonlyInput]="true"
                                                    [showIcon]="true"
                                                    #inputIcon
                                                    inputId="icon"
                                                    inputStyleClass="calendar-inputtext"
                                                    dateFormat="dd-M-yy"
                                                    [maxDate]="maxContactQcDate"
                                                    >
                                                </p-calendar>
                                            </div>
                                        </div>
                                        <div class="col-4 xl:col-5">
                                            <label class="text-xs font-bold" *ngIf="!displayLink(contactGeneric.iCopy?.qcUserEmailAddress)" for="">{{ contactGeneric.iCopy?.contactQcUser }}</label>
                                            <a class="font-bold basic-link label-user cursor-pointer" *ngIf="displayLink(contactGeneric.iCopy?.qcUserEmailAddress)" (click)="openUserModal(contactGeneric.iCopy?.contactQcUser)">{{ contactGeneric.iCopy?.contactQcUser }}</a>
                                            <label class="text-xs font-bold px-0 md:px-1 lg:px-1 xl:px-1">{{ contactGeneric.iCopy?.contactQcDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                                        </div>
                                </div>
                                <!-- Verified Mail -->
                                <div class="grid">
                                    <div class="col-10 justify-content-end text-right px-0">
                                        <p-checkbox
                                            class="text-xs font-bold labelFirst"
                                            formControlName="mailAddressVerifiedBool"
                                            [binary]="true" (click)="onAddressVerifiedClicked()"
                                            label="{{'contact.common.verifiedMailAddress' | translate}}:"
                                        >
                                        </p-checkbox>
                                    </div>
                                    <div class="col-2 text-left"></div>
                                </div>
                                <div class="grid -mr-5">
                                    <label class="col-10 flex justify-content-end text-xs mt-2">{{ contactGeneric.iCopy?.mailAddressVerifiedDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                                    <div class="col-2 text-left"></div>
                                </div>
                            </div>
                        </div>
                        <!-- ********** END ENTITY CONTACTS INFORMATION SECTION ********** -->
                        </form>
                    </div>
                    <!-- BUTTONS -->
                    <div class="flex justify-content-center flex-wrap">
                            <button 
                                *ngIf="readOnly"
                                 class="btn-row flex align-items-center justify-content-center w-12rem h-2rem m-2" 
                                (click)="close()">{{'contact.common.close'| translate}}
                            </button>
                            <button 
                                *ngIf="!readOnly"
                                [disabled]="disabledViewContactAssociations" 
                                class="btn-row flex align-items-center justify-content-center w-12rem h-2rem m-2" 
                                (click)="openContactAssociationsModal()">{{'contact.common.viewContactAssociations'| translate}}
                            </button>
                            <button 
                                class="btn-row flex align-items-center justify-content-center w-12rem h-2rem m-2" 
                                (click)="saveRecordButton()" 
                                [disabled]="disabledSaveRecordButton">{{'contact.common.saveContactAndClose'| translate}}</button>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Comments">
                      <div class="p-3">
                            <app-notes #commentsComponent
                                      [notes]="contactGeneric?.iCopy?.contactDisplay?.contactsCommentsDisplay"
                                      [entityId]="contactGeneric?.iCopy?.contactDisplay?.contactId"
                                      [showSell]="false"
                                      [createInstance]="createContactCommentInstance"
                                      (noteChanges)="contactCommentsChangeListener($event)">
                            </app-notes>
                        </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
