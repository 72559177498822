import { Directive, ElementRef, QueryList, ViewChildren, ViewChild, Input, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from "primeng/table";
import { CompanySearchParams } from '../../core/interfaces/search/icompany-search-params';
import { BaseComponent } from "src/app/base.component";
import { Constants, EntityPreferenceSufix, PreferencePrefix } from "src/app/core/models/constants";
import { PreferencesSectionService } from "src/app/preferences-section/preferences-section.service";
import { StringUtils } from "src/app/core/utils/string-utils";

@Directive()
export abstract class BaseModalComponent {
    @ViewChild('searchTable', { static: false }) protected searchTable: Table;
    @ViewChildren('tr') private rows: QueryList<ElementRef>;
    
    @Input() baseIndex: number = 10000;
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;
    rowsPerPage: number;
    tableKey: string = EntityPreferenceSufix.Entity;

    companySearch: CompanySearchParams = {
        company_name: '',
        industry_code: '',
        phys_city: '',
        phys_country: '',
        phys_state: '',
        company_status: [],
        company_classification: '',
        phys_county: '',
        previus_name: '',
        record_status: '',
        recordedSearch: false
    }
    constructor( public activeModal: DynamicDialogRef,
                 public config: DynamicDialogConfig,
                 public preferencesService: PreferencesSectionService
                 ) {
    }

    ngOnInit() {
        this.baseIndex = (this.config.data?.baseIndex) ? this.config.data?.baseIndex : this.baseIndex;
        this.setTabActionToCloseButton();
    }

    initPaginatorPreferences(): void {
        this.rowsPerPage = this.preferencesService.getCommonRowsPerPage(PreferencePrefix.Search+this.tableKey);
    }

    cancel(): void {
        this.activeModal.close();
    }

    resetTable() {
        this.searchTable.reset();
    }

    onPageChange(event): void {
        this.preferencesService.updateCommonPreferences(PreferencePrefix.Search+this.tableKey, StringUtils.toStringNeverNull(event.rows));
    }

    protected focusFirstElement(time: number = 25) {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
            }
        }, time);
    }

    protected selectFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 25);
    }

    setTabActionToCloseButton() {
        const closeButton = this.getCloseButton();
        if (closeButton) {
            closeButton.addEventListener('keydown', (event) => this.focusFirstElementOnTab(event));
        }
    }

    focusFirstElementOnTab(event: Event): void {
        if((event as KeyboardEvent).key !== 'Tab' || (event as KeyboardEvent).shiftKey) {
            return;
        }

        const focusableElements = BaseComponent.getFocusableElements(this.baseIndex);
        if (focusableElements.length > 0) {
            event.preventDefault();
            event.stopImmediatePropagation();

            focusableElements[0].focus();
        }
    }

    focusCloseButton(event: Event, reverse: boolean = false): void {
        if((event as KeyboardEvent).key !== 'Tab') {
            return;
        }
        if(reverse && !(event as KeyboardEvent).shiftKey) {
            return;
        }
        
        const closeButton = this.getCloseButton();
        if (closeButton) {
            event.preventDefault();
            event.stopImmediatePropagation();
    
            closeButton.focus();
        }
    }

    getCloseButton(): HTMLElement {
        const closeIcon = document.querySelector('.p-dialog-header-close-icon');
        let closeButton: HTMLElement = closeIcon as HTMLElement;
        while(closeButton && closeButton.tagName !== 'BUTTON') {
            closeButton = closeButton.parentElement;
        }
        return closeButton;
    }
}
