import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { UserModalComponent } from 'src/app/components/modals/user-modal/user-modal.component';
import { PendingRequestsInterceptor } from 'src/app/core/interceptor/pending-requests.interceptor';
import { MapitEntity } from 'src/app/core/models/common';
import { Constants } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { EventType } from 'src/app/core/models/enumerations/event-type';
import { EntityEvent } from 'src/app/core/models/event';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { TabIndexService } from 'src/app/core/services/tabindex.service';
import { MessageHandlerService } from './../../components/messages/message-handler/message-handler.service';
import { HeaderService } from './header.service';
import { TopNavComponent } from '../top-nav/top-nav.component';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @ViewChild(TopNavComponent) topNav:TopNavComponent;

    entityId: number;
    releaseDate: string;
    relaseUser: string;
    lastUpdateStatus: string;
    lockId: number;
    lockError: string;
    lockSameUser: boolean;
    title: string;
    changedMessage: string;
    changedMessageNewEnity: string;
    headerEntity: boolean = false;
    tKeyExitRecordButtonTitle: string = 'exit_record';
    tKeySaveButtonTitle: string = 'save';
    exitButtonDisabled: () => boolean = () => this.lockId == null;
    saveButtonDisabled: () => boolean = () => this.pendingRequestsInterceptor.pendingRequests || !(this.isNewEntity || this.lockId > 0 && this.lockError == null);

    lockMessageStart: string;
    lockMessageEnd: string;
    lockUserLink: string;
    intDataDepAmendState: number;
    intDataDepMigState: number;
    intDataDepMigError: string;
    updateType: number;
    qcDate: string;
    lastDate: string;
    private headerRestriction: any;
    private headerChanged: Subscription;
    private releaseChange: Subscription;
    private headerEvent: Subscription;
    private disableFormChange: Subscription;
    disabledEntity: boolean = false;
    warningMsgLink: string = '';
    showClientView: boolean = false;
    showPDFLink: boolean = false;
    showMapItLink: boolean = false;
    entityType: EntityName = null;
    entityName: string = null;
    parentEntityType: EntityName = null;
    parentEntityId: number;
    entityHasSystem: boolean = false;
    entitySystemField: string = null;
    entityBaseUrl: string = null;
    entityMapParams: any = null;
    mapitEntity: MapitEntity = null;
    isDash: boolean = false;
    isNewEntity: boolean = false;

    constructor(
        private cdref: ChangeDetectorRef,
        private entityCommonService: EntityCommonService,
        private recordLockingFlow: RecordLockingFlow,
        public headerService: HeaderService,
        public sharedService: SharedService,
        private authService: AuthService,
        private messageHandlerService: MessageHandlerService,
        private tabIndexService: TabIndexService,
        private renderer: Renderer2,
        private pendingRequestsInterceptor: PendingRequestsInterceptor,
        private dialogService: DialogService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        public datePipe: DatePipe,
        public entityUtilsService: EntityUtilsService,
    ) { }

    ngOnInit(): void {
        this.changedMessage = this.translate.instant('common.warningEditedRecord');
        this.changedMessageNewEnity = this.translate.instant('common.warningEditedRecordNewEntity');
        this.headerService.initHeaderSubjects();
        this.headerChanged = this.headerService.headerChanged$.subscribe(headerData => {
            this.title = headerData?.title;
            this.headerEntity = headerData?.headerEntity;
            this.entityType = headerData?.entityType;
            this.isDash = this.entityType === undefined || this.entityType === EntityName.DASHBOARD;
            this.headerRestriction = headerData?.headerRestriction;
            this.tKeyExitRecordButtonTitle = (headerData?.tKeyExitRecordButtonTitle != null) ? headerData?.tKeyExitRecordButtonTitle : this.tKeyExitRecordButtonTitle;
            this.tKeySaveButtonTitle = (headerData?.tKeySaveButtonTitle != null) ? headerData?.tKeySaveButtonTitle : this.tKeySaveButtonTitle;
            this.saveButtonDisabled = (headerData?.saveButtonDisabled != null) ? headerData?.saveButtonDisabled : this.saveButtonDisabled;
            this.cdref.detectChanges();
            if(this.entityType === EntityName.PREFERENCES) {
                this.changedMessage = this.translate.instant('preferences.changedMessage');
            }
        });
        this.releaseChange = this.headerService.releaseChange$.subscribe(releaseData => {


            if (releaseData) {
                this.entityId = releaseData?.entityId;
                this.entityName = releaseData?.entityName;
                this.entityHasSystem = releaseData?.entityHasSystem;
                this.entitySystemField = releaseData?.entitySystemField;
                this.entityBaseUrl = releaseData?.entityBaseUrl;
                this.entityMapParams = releaseData?.entityMapParams;
                this.mapitEntity = releaseData?.mapitEntity;
                this.releaseDate = releaseData?.releaseDate;
                this.relaseUser = releaseData?.releaseUser;
                this.lastUpdateStatus = releaseData?.lastUpdateStatus;
                this.lockId = releaseData?.lockId;
                this.lockError = releaseData?.lockError;
                this.lockSameUser  = releaseData?.lockSameUser;
                this.intDataDepAmendState = releaseData?.intDataDepAmendState;
                this.intDataDepMigState = releaseData?.intDataDepMigState;
                this.intDataDepMigError = this.formatMessage(releaseData?.intDataDepMigError);
                this.updateType = releaseData?.updateType;
                this.qcDate = releaseData?.qcDate;
                this.lastDate = releaseData?.lastDate;
                this.recordStatusValidation();
                this.checkValidationOrReleaseWarning();
                this.showPDFLink = this.lastDate !== null;
                this.showMapItLink = this.showMapitLink();
                this.isNewEntity = releaseData?.isNewEntity;
                this.setShowClientView();
                this.parentEntityId = releaseData?.parentEntityId;
                this.parentEntityType = releaseData?.parentEntityType;
            } else {
                this.clearHeader();
            }

            this.tabIndexService.disableAll(this.renderer);
        });

        this.headerEvent = this.headerService.headerEvent$.subscribe((event: EntityEvent) => {
                if (event !== null) {
                    if (event.type === EventType.CLEAR_DATA) {
                        this.clearHeader();
                    }
                }
            });

        this.disableFormChange = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledEntity = disable;
        });
        this.initNewRecord();
    }

    showMapitLink(): boolean {
        return this.entityType && 
               this.entityType !== EntityName.DASHBOARD &&
               this.entityType !== EntityName.UNIT &&
               this.entityType !== EntityName.PLANT_LTSA &&
               this.entityType !== EntityName.UNIT_LTSA &&
               this.entityType !== EntityName.PIPELINE_LTSA &&
               this.entityType !== EntityName.TANK &&
               this.entityType !== EntityName.AREA &&
               this.entityType !== EntityName.TURBINE &&
               this.entityType !== EntityName.OEQUIPMENT &&
               this.entityType !== EntityName.MEQUIPMENT &&
               this.entityType !== EntityName.BOILER &&
               this.entityType !== EntityName.PROJECT_CONFIRMED &&
               this.entityType !== EntityName.PROJECT_UNCONFIRMED &&
               this.entityType !== EntityName.OFFLINE_EVENT &&
               this.entityType !== EntityName.PREFERENCES;
    }

    recordStatusValidation(): void {
        let qcDate = new Date(this.qcDate);
        let releaseDate = new Date(this.releaseDate);
        let lastDate = new Date(this.lastDate);
        if (this.lockError?.length > 0) {
            if(this.lockSameUser) {
               this.lockMessageStart = this.lockError;
               this.lockMessageEnd = '';
               this.lockUserLink = '';
            } else {
               this.lockMessageStart = this.lockError.substring(0, this.lockError.indexOf('(') + 1);
               this.lockMessageEnd = this.lockError.substring(this.lockError.indexOf(')'), this.lockError.length);
               this.lockUserLink = this.lockError.substring(this.lockError.indexOf('(') + 1, this.lockError.indexOf(')'));
            }           
        }
        if (this.intDataDepAmendState == 1) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionAmend');
        } else if (this.updateType == 1) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionUpdateRelease');
        } else if ((this.qcDate != null && this.releaseDate != null) && (qcDate > releaseDate)) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionRelease');
        } else if (this.qcDate != null && this.releaseDate == null) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionRelease');
        } else if ((this.qcDate != null && this.releaseDate != null) && (lastDate > releaseDate)) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionEdited');
        } else if (this.lastDate != null && this.releaseDate == null) {
            this.headerService.cautionMessage = this.translate.instant('common.cautionQcEd');
        } else {
            this.headerService.cautionMessage = "";
        }
    }

    checkValidationOrReleaseWarning() {
        this.warningMsgLink = '';
        const existMigError = this.intDataDepMigError != null && this.intDataDepMigError.trim().length > 0;

        if (this.intDataDepMigState == 2 || this.intDataDepMigState == 3 || this.intDataDepMigState == 5) {
            this.warningMsgLink = Constants.WARNING_RECORD_FAILED_RELEASE;
        } else if (existMigError) {
            this.warningMsgLink = Constants.WARNING_RECORD_FAILED_VALIDATION;
        }
    }

    ngOnDestroy() {
        this.headerChanged?.unsubscribe();
        this.releaseChange?.unsubscribe();
        this.headerEvent?.unsubscribe();
        this.disableFormChange?.unsubscribe();
    }

    saveEntity() {
        if (!this.pendingRequestsInterceptor.pendingRequests) {
            if (this.entityId !== null && this.lockId !== null && this.lockId !== undefined) {
                this.recordLockingFlow.lockRefresh(this.entityType, String(this.entityId), String(this.lockId));
            }

            this.entityCommonService.sendSaveEvent();
        }
    }

    initNewRecord() {
        if (this.activatedRoute.snapshot.params['newRecord']) {
            this.entityCommonService.sendNewRecordEvent();
        }
    }

    exitRecord() {
        if (this.headerService.changeForm && !this.disabledEntity) {
            const selectOption = (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.YES) {
                    this.entityCommonService.sendSaveEvent();
                } else if (resp.event === MessageEvent.NO && this.authService.isLoggedIn()) {
                    this.recordLockingFlow.clearLockFlow(this.entityType, String(this.entityId));

                    this.sharedService.disableForm(false);
                    this.entityCommonService.sendClearEvent(this.entityType);
                    this.clearHeader();
                }
            }
            this.messageHandlerService.show(Constants.CONFIRM_SAVE_RECORD_EDITED, MessageType.CONFIRM_OK_CANCEL, selectOption)
        } else {
            this.recordLockingFlow.clearLockFlow(this.entityType, String(this.entityId));
            this.entityCommonService.sendClearEvent(this.entityType);

            this.sharedService.disableForm(false);
            this.clearHeader();
        }
    }

    discardChanges(): void {
        this.clearHeader();
        this.entityCommonService.sendReloadEvent();
        this.headerService.changeForm = false;
        this.headerService.isReload = true;
        this.headerService.discardChangesEvent();
    }

    editRecord(): void {
        this.entityCommonService.sendReloadEvent();
    }

    clearHeader(): void {
        this.entityId = null;
        this.entityName = '';
        this.releaseDate = '';
        this.relaseUser = '';
        this.lastUpdateStatus = '';
        this.headerService.changeForm = false;
        this.headerService.isReload = false;
        this.lockId = null;
        this.lockError = '';
        this.lockMessageStart = '';
        this.lockMessageEnd = '';
        this.lockUserLink = '';
        this.headerService.cautionMessage = '';
        this.warningMsgLink = '';
        this.showPDFLink = false;
        this.showMapItLink = false;
        this.showClientView = false;
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    openUserModal(userModal: string) {
        const modalRef = this.dialogService.open(UserModalComponent, {
            header: `User Details for ${userModal}`,
            width: '30rem',
            data: {
                user: userModal,
                entityName: this.entityType,
                entityId: this.entityId
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
    }

    showWarningMsg() {
        const msgType = this.warningMsgLink === Constants.WARNING_RECORD_FAILED_VALIDATION ? MessageType.VALIDATION_ERROR : MessageType.RELEASE_ERROR;
        this.messageHandlerService.show(this.intDataDepMigError, msgType);
    }

    goToLocation() {
        if (this.validateMapit()) {
            if (this.entityHasSystem) {
                this.goSystemLocation(this.entityMapParams);
            } else {
                this.headerService.windowOpenMapIt(this.entityMapParams, false, null,
                    this.entityBaseUrl, this.entityType, this.entityId);
            }
        }
    }

    goSystemLocation(params: string) {
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                this.headerService.windowOpenMapIt(params, false, null,
                    this.entityBaseUrl, this.entityType, this.entityId);
            } else if (resp.event == MessageEvent.CANCEL) {
                this.headerService.windowOpenMapIt(params, true,
                    this.entitySystemField, this.entityBaseUrl, this.entityType, this.entityId);
            }
        }
        this.messageHandlerService.show(this.translate.instant('asset.systemMappingPref', { entity: this.entityType }),
            MessageType.MAP_IT_PREFERENCES, selectOpt,
            this.translate.instant('asset.systemMappingPrefOk', { entity: this.entityType }),
            this.translate.instant('asset.systemMappingPrefCancel', { entity: this.entityType }));
    }

    private validateMapit(): boolean {
        let alertMsg: string = '';
        if (this.entityType === EntityName.ASSET_PIPELINE ||
            this.entityType === EntityName.ASSET_TRANSMISSION_LINE) {
            if (this.mapitEntity.oriType === null || this.mapitEntity.oriType === '') {
                alertMsg += this.translate.instant('mapValidations.oriType') + '<br>';
            }
            if (this.mapitEntity.oriId === null || this.mapitEntity.oriId === '') {
                alertMsg += this.translate.instant('mapValidations.oriId') + '<br>';
            }
            if (this.mapitEntity.oriName === null || this.mapitEntity.oriName === '') {
                alertMsg += this.translate.instant('mapValidations.oriName') + '<br>';
            }
            if (this.mapitEntity.oriLat === null || this.mapitEntity.oriLat === '') {
                alertMsg += this.translate.instant('mapValidations.oriLat') + '<br>';
            }
            if (this.mapitEntity.oriLong === null || this.mapitEntity.oriLong === '') {
                alertMsg += this.translate.instant('mapValidations.oriLong') + '<br>';
            }
            if (this.mapitEntity.desType === null || this.mapitEntity.desType === '') {
                alertMsg += this.translate.instant('mapValidations.destType') + '<br>';
            }
            if (this.mapitEntity.desId === null || this.mapitEntity.desId === '') {
                alertMsg += this.translate.instant('mapValidations.destId') + '<br>';
            }
            if (this.mapitEntity.desName === null || this.mapitEntity.desName === '') {
                alertMsg += this.translate.instant('mapValidations.destName') + '<br>';
            }
            if (this.mapitEntity.desLat === null || this.mapitEntity.desLat === '') {
                alertMsg += this.translate.instant('mapValidations.destLat') + '<br>';
            }
            if (this.mapitEntity.desLong === null || this.mapitEntity.desLong === '') {
                alertMsg += this.translate.instant('mapValidations.destLong') + '<br>';
            }
            let connIndex: number = 0;
            this.mapitEntity.connections.forEach(conn => {
                connIndex++;
                if (conn.id === null || conn.id === '') {
                    alertMsg += this.translate.instant('mapValidations.connId') + ' ' + connIndex + '<br>';
                }
                if (conn.connType === null || conn.connType === '') {
                    alertMsg += this.translate.instant('mapValidations.connType') + ' ' + connIndex + '<br>';
                }
                if (conn.connName === null || conn.connName === '') {
                    alertMsg += this.translate.instant('mapValidations.connName') + ' ' + connIndex + '<br>';
                }
                if (conn.latitude === null || conn.latitude === '') {
                    alertMsg += this.translate.instant('mapValidations.connLat') + ' ' + connIndex + '<br>';
                }
                if (conn.longitude === null || conn.longitude === '') {
                    alertMsg += this.translate.instant('mapValidations.connLong') + ' ' + connIndex + '<br>';
                }
                if (conn.industryCode === null || conn.industryCode === '') {
                    alertMsg += this.translate.instant('mapValidations.connCode') + ' ' + connIndex + '<br>';
                }
            });
        }
        else {
            if (this.mapitEntity.entityId === null || this.mapitEntity.entityId === '') {
                alertMsg += this.entityType + '' + this.translate.instant('mapValidations.id') + '<br>';
            }
            if (this.mapitEntity.entityName === null || this.mapitEntity.entityName === '') {
                alertMsg += this.entityType + '' + this.translate.instant('mapValidations.name') + '<br>';
            }
            if (this.mapitEntity.latitude === null || this.mapitEntity.latitude === '') {
                alertMsg += this.translate.instant('mapValidations.lat') + '<br>';
            }
            if (this.mapitEntity.longitude === null || this.mapitEntity.longitude === '') {
                alertMsg += this.translate.instant('mapValidations.long') + '<br>';
            }
        }

        if (alertMsg.length > 0) {
            this.messageHandlerService.show(alertMsg, MessageType.MAP_IT_VALIDATION_ERROR);
        }

        return alertMsg.length === 0;
    }

    downloadPDF() {
        this.entityUtilsService.downloadPDF(this.entityType, this.entityId);
    }

    clientView() {
        let entityType = this.entityType;
        let entityId = this.entityId.toString();
        if(this.entityType === EntityName.PLANT_LTSA || 
                this.entityType === EntityName.UNIT_LTSA) {
            entityType = this.parentEntityType;
            entityId = this.parentEntityId.toString();

        }
        this.headerService.openClientView(entityType, entityId);
    }

    isExitButtonDisabled(): boolean {
        return this.exitButtonDisabled();
    }

    isSaveButtonDisabled(): boolean {
        return this.saveButtonDisabled();
    }

    setShowClientView() {
        const rowIsMigrated = this.releaseDate != null && this.releaseDate != '';
        const hasRestriction = this.headerRestriction && this.headerRestriction?.showClientView;
        const entityViewData = Constants.ENTITIES_VIEWS_DATA[Object.keys(EntityName).find(key => EntityName[key] === this.entityType)];

        if (rowIsMigrated && hasRestriction && entityViewData && !this.showClientView) {
            this.sharedService.getEntityInLive(entityViewData[0], this.entityId, entityViewData[1]).subscribe(
                (resp: any) => {
                    this.showClientView = resp && resp.length > 0;
                }
            );
        }
    }

    formatMessage(intDataDepMigError: string){
        if(intDataDepMigError != null) {
            intDataDepMigError = intDataDepMigError.replace(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g, (match, dateStr) => {
                return this.datePipe.transform(new Date(dateStr), 'dd-MMM-y HH:mm:ss');
            });
        }
        return intDataDepMigError;
    }

    serverNotificationCount(): number {
       return this.topNav?.serverNotificationCount ?? 0;
    }

    showNotifications(): void {
        this.topNav.showNotifications();
    }

}
