import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { Address } from "../../../address";
import { OperationType } from "../../../enumerations/operation-type";
import { AssetsDisplay } from "../../base/display/assets-display";
import { AssetsSites } from "../save/assets-sites";
import { OffshoreAddress } from '../../../offshoreAddress';
import { EntityName } from "../../../enumerations/entity-name";

export class AssetsSitesDisplay extends AssetsSites {
    statusDesc: string;
    countyName: string;
    mailCountryName: string;
    mailCountryAbrev: string;
    mailStateName: string;
    mailStateAbrev: string;
    mailCityName: string;
    defCountryCode: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;

    physicalAddress: Address;
    physicalEmail: Address;
    offshoreAddress: OffshoreAddress;

    assetDisplay: AssetsDisplay;

    constructor(entity: any) {
        super(entity);

        this.statusDesc = entity?.statusDesc ?? null;
        this.countyName = entity?.countyName ?? null;
        this.mailCountryName = entity?.mailCountryName ?? null;
        this.mailCountryAbrev = entity?.mailCountryAbrev ?? null;
        this.mailStateName = entity?.mailStateName ?? null;
        this.mailStateAbrev = entity?.mailStateAbrev ?? null;
        this.mailCityName = entity?.mailCityName ?? null;
        this.defCountryCode = entity?.defCountryCode ?? null;
        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress || null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;

        this.assetDisplay = AssetsDisplay.BuildAssetsDisplay(entity.assetDisplay);
        this.assetDisplay.assetTypeId = 4;
        this.asset = this.assetDisplay;

        this.physicalAddress = Address.BuildPhysicalAddressFromAsset(entity.assetDisplay || {});
        this.physicalEmail = Address.BuildMailingAddressFromAsset(entity);
        this.offshoreAddress = OffshoreAddress.BuildOffshoreAddressFromAsset(entity.assetDisplay || {});
    }

    static BuildSiteDisplay(entity: any): AssetsSitesDisplay {
      return new AssetsSitesDisplay(entity);
    }

    static BuildNewSite(response: any): AssetsSitesDisplay {
      return new AssetsSitesDisplay({
        assetSiteId: response[0].ASSET_SITES_ID,
        assetId: response[0].ASSET_ID,
        operation: OperationType.INSERT,
        intDataDepValState: OperationType.INSERT
      });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.assetDisplay?.lastUser,
            entryUser: this.assetDisplay?.entryUser,
            prevUser: this.assetDisplay?.prevUser,
            qcUser: this.assetDisplay?.qcUser,
            lastDate: this.assetDisplay?.lastDate,
            entryDate: this.assetDisplay?.entryDate,
            prevDate: this.assetDisplay?.prevDate,
            qcDate: this.assetDisplay?.qcDate,
            releaseUser: this.assetDisplay?.releaseUser,
            releaseDate: this.assetDisplay?.releaseDate,
            amendmentUser: this.assetDisplay?.amendmentUser,
            amendmentDate: this.assetDisplay?.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.SITE,
            entityId: this.assetId
        };
    }

    setPhysicalAddress(address: Address): void {
      this.assetDisplay.originPhysPostalCode = address.zipCode;
      this.assetDisplay.originPhysAddressV1 = address.address1;
      this.assetDisplay.originPhysAddressV2 = address.address2;
      this.assetDisplay.originPhysCountry = address.c_id;
      this.assetDisplay.originPhysState = address.s_id;
      this.assetDisplay.originPhysCity = address.ci_id;
      this.assetDisplay.originPhysCounty = address.co_id;
    }

    setMailingAddress(address: Address): void {
      this.mailPostalCode = address.zipCode;
      this.mailAddressV1 = address.address1;
      this.mailAddressV2 = address.address2;
      this.mailCountry = String(address.c_id);
      this.mailState = String(address.s_id);
      this.mailCity = String(address.ci_id);
    }

    setOffshoreAddress(offshoreAddress: OffshoreAddress): void {
        this.assetDisplay.originOffshoreBlockV1 = offshoreAddress.originOffshoreBlockV1;
        this.assetDisplay.originOffshoreBlockV2 = offshoreAddress.originOffshoreBlockV2;
        this.assetDisplay.originOffshoreWaterbodyId = offshoreAddress.originOffshoreWaterbodyId;
        this.assetDisplay.originOffshoreFieldId = offshoreAddress.originOffshoreFieldId;
        this.assetDisplay.originOffshoreAreaId = offshoreAddress.originOffshoreAreaId;
        this.assetDisplay.origOffshoreStateDesignation = offshoreAddress.origOffshoreStateDesignation;
      }

    applyChangedFields(changeObj: any) {
        this.changedFields = {};
        this.assetDisplay.changedFields = {};

        for(const field in changeObj) {
            let auxObj = {};
            auxObj[field] = 0;
            if(this.hasOwnProperty(field)) {
                this.changedFields[field] = 0;
            } else if(this.assetDisplay.hasOwnProperty(field)) {
                this.assetDisplay.changedFields[field] = 0;
            }
        }
    }

    prepareSaveCollection() {
        this.assetDisplay.prepareSaveCollection();
        this.asset = this.assetDisplay;
    }

}
